export const CodeTr = [
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "Görüntülerdeki nesneleri ve olayları tanımak için, metinleri analiz etmek için ve sesleri tanımak için web sitesi veya API aracılığı ile kullanılabilir. ",
    pricing: ["0", "1"],
    typeIcon: ["1", "2", "5", "8", "10"],
  },
  {
    id: 2002,
    name: "Blacbox ",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Blackbox AI",
    webUrl: "https://www.blackbox.global/",
    works_on:
      " Kullanıcıların metinleri ve kodları analiz etmelerine yardımcı olan bir yapay zeka platformudur. ",
    pricing: ["0", "1"],
    typeIcon: ["2"],
  },
  {
    id: 2003,
    name: "Github Copilot",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Github ve Open AI",
    webUrl: "https://github.com/features/copilot",
    works_on:
      "Kod tamamlama aracıdır. Visual Studio Code, Visual Studio, Neovim ve diğer entegre geliştirme ortamlarının (IDE'ler) kullanıcılarına destek vermek için tasarlanmıştır.",
    pricing: ["0", "1"],
    typeIcon: ["2"],
  },
];

export const CodeEn = [
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "It can be used via the website or API to recognize objects and events in images, analyze texts, and recognize sounds.",
    pricing: ["0", "1"],
    typeIcon: ["1", "2", "5", "8", "10"],
    keywords:
      "Clarif AI, image identification, photo identification, object identification, text analysis, voice recognition, video identification",
  },
  {
    id: 2002,
    name: "Blacbox ",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Blackbox AI",
    webUrl: "https://www.blackbox.global/",
    works_on:
      "It is an artificial intelligence platform that helps users analyze texts and codes.",
    pricing: ["0", "1"],
    typeIcon: ["2"],
    keywords:
      "Blackbox, coding, code assistant, coding artificial intelligence, visual studio code",
  },
  {
    id: 2003,
    name: "Github Copilot",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Github ve Open AI",
    webUrl: "https://github.com/features/copilot",
    works_on:
      "It is a code completion tool. Visual Studio Code is designed to support users of Visual Studio, Neovim, and other integrated development environments (IDEs).",
    pricing: ["0", "1"],
    typeIcon: ["2"],
    keywords:
      " Github Copilot, coding, code assistant, coding artificial intelligence, visual studio code",
  },
];
