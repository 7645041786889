import { createSlice } from "@reduxjs/toolkit";

const aiSlice = createSlice({
  name: "ai",
  initialState: {
    searchTerm: "",
    data: [],
  },
  reducers: {
    addAi(state, action) {
      console.log("Adding AI:", action.payload.data);

      const newData = action.payload.data;

      // Eğer aynı veri zaten varsa eklemeyi engelle
      if (!state.data.some((item) => item.data.id === newData.id)) {
        state.data.push({ data: newData });
      }

      console.log("Updated Data:", state.data);
    },

    changeSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
  },
});

export const { addAi, changeSearchTerm } = aiSlice.actions;
export const aiReducer = aiSlice.reducer;
