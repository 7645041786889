import Tooltip from "@mui/material/Tooltip";
import Web from "../assets/images/about/web.png";
import Phone from "../assets/images/about/phone.png";
import Adress from "../assets/images/about/adress.png";
import Mail from "../assets/images/about/mail.png";
import Instagram from "../assets/images/about/instagram.png";
import LinkedIn from "../assets/images/about/linkedin.png";
import WebQr from "../assets/images/about/webQr.png";
import InstagramQr from "../assets/images/about/instagramQr.png";
import LinkedInQr from "../assets/images/about/linkedinQr.png";
import Footer from "./Footer";
import "./css/About.css";

function About({ langPropsData }) {
  return (
    <>
      <div className="about-cover">
        <h1 className="contact-text">
          {langPropsData === "EN" ? "CONTACT" : "İLETİŞİM"}
        </h1>
        <div className="about-content">
          <div className="about-div">
            <div className="info-text-class">+90 (530) 341 82 84</div>
            <img className="about-div-under-img" src={Phone} alt="" />
            <div className="about-info-under-text">
              {langPropsData === "EN" ? "Phone" : "Telefon"}
            </div>
          </div>

          <div className="about-div">
            <a
              className="info-text-class info-mail-class"
              style={{ color: "wheat" }}
              href="mailto:info@kadimyazilim.com"
            >
              info@kadimyazilim.com
            </a>
            <img className="about-div-under-img" src={Mail} alt="" />
            <div className="about-info-under-text">Mail</div>
          </div>
          <div className="about-div">
            <div className="info-text-class">MANİSA - TÜRKİYE</div>
            <img className="about-div-under-img" src={Adress} alt="" />
            <div className="about-info-under-text">
              {langPropsData === "EN" ? "Adress" : "Adres"}
            </div>
          </div>
        </div>
        <div className="about-content-qr">
          <a
            href="https://www.kadimyazilim.com"
            target="blank"
            className="about-div-a"
          >
            <img
              className="about-div-under-img"
              src={Web}
              alt="kadim yazılım"
            />
            <Tooltip
              title={langPropsData === "EN" ? "Our Web Site" : "Web Sitemiz"}
              placement="bottom"
            >
              <img
                className="about-div-under-qr"
                src={WebQr}
                alt="kadim yazılım"
              />
            </Tooltip>
            <div className="about-social-under-text">Web Site</div>
          </a>
          <a
            href="https://www.instagram.com/kadimyazilim"
            target="blank"
            className="about-div-a"
          >
            <img
              className="about-div-under-img"
              src={Instagram}
              alt="kadim yazılım"
            />
            <Tooltip
              title={
                langPropsData === "EN" ? "Our Instagram" : "İnstagram Hesabımız"
              }
              placement="bottom"
            >
              <img
                className="about-div-under-qr"
                src={InstagramQr}
                alt="kadim yazılım"
              />
            </Tooltip>
            <div className="about-social-under-text">İnstagram</div>
          </a>
          <a
            href="https://www.linkedin.com/company/kadim-yazilim/"
            target="blank"
            className="about-div-a"
          >
            <img
              className="about-div-under-img"
              src={LinkedIn}
              alt="kadim yazılım"
            />
            <Tooltip
              title={
                langPropsData === "EN" ? "Our LinkedIn" : "LinkedIn Hesabımız"
              }
              placement="bottom"
            >
              <img
                className="about-div-under-qr"
                src={LinkedInQr}
                alt="kadim yazılım"
              />
            </Tooltip>
            <div className="about-social-under-text">Linkedin</div>
          </a>
        </div>
        <br />
        <Footer langPropsData={langPropsData} />
      </div>
    </>
  );
}

export default About;
