export const keywordTr = [
  {
    keywords:
      "fotoğraf tanımlama, nesne tanımlama, metin analizi, ses tanıma, video tanımlama, sesten metin haline dönüştürme, convert audio to text, sesten metine dönüştürme, videodan metin oluşturma, animasyon oluşturma, video oluşturma, ses oluşturma, yapay zeka oluşturma, chatbot, kodsuz yapay zeka, kodlamasız yapay zeka, kodlama, kod yardımcısı, visual studio code, kodlama yapay zeka, metin oluşturma, içerik üretme, çeviri, soru cevaplama, copy.ai, intihal, benzerlik tespiti, makale özeti, makale özetleme, metin özetleme, yazım hatası, e-posta cevaplama, yeniden yazma, dilbilgisi, tutarsızlık tespit etme, sunum oluşturma, görüntü oluşturma, dall-e 2, dall-e 3, logoai, logo oluşturma, logo yapma, araştırma yapma, araştırma yapay zeka, veri analizi, web otomasyon, seo, arama motoro optimizasyonu, web site, web site performansı, youtube, analiz, youtube analiz, web site analiz, web site performans, web site performans analizi, youtube kanal performansı izleme, youtube kanal performans analizi, web site performans izleme, web site performans analizi, metin oluşturma, soru cevaplama, çeviri yapma, planlama, öneri, pdf okuma, soru cevap, içerik üretme, doğal dil işleme, makale oluşturma, sosyal medya gönderisi, e-posta oluşturma, reklam oluşturma, blog gönderisi oluşturma, soru cevaplama, çeviri yapma, metinde düzenlemeler yapma, görüntü oluşturma, animasyon oluşturma, görsel oluşturma, görüntü oluşturma, çizim yapma, görsel içerik oluşturma, görüntü oluşturma, çizim yapma, video oluşturma, görsel içerik oluşturma, video oluşturma, resim oluşturma, görüntü oluşturma, çizim yapma, çizim oluşturma, video oluşturma, dosya dönüştürme, görsel düzenleme, düzenleme, resim düzenleme, fotoğraf düzenleme, video düzenleme",
  },
];

export const keywordEn = [
  {
    keywords:
      "image identification, photo identification, object identification, text analysis, voice recognition, video identification, audio to text conversion, convert audio to text, create text from video, create animation, create video, create audio, building artificial intelligence, chatbot, no-code AI, coding, code assistant, coding artificial intelligence, visual studio code, text creation, content production, translation, question answering, natural language processing, copy.ai, plagiarism, similarity detection, article summary, article summarization, text summarization, typo, email reply, rewrite, grammar, inconsistency detection, presentation creation, image creation, rendering, dall-e 2, dall-e 3, logoai, logo creation, logo making, doing research, research artificial intelligence, data analysis, web automation, seo, search engine optimization, website, website performance, youtube, analysis, youtube analysis, website analysis, website performance analysis, youtube channel performance monitoring, youtube channel performance analysis, text creation, content generation,translation, planning, suggestion, pdf reading, question and answer, content creation, article creation, social media post, email creation, ad creation, blog post creation, translating, making edits to the text, draw, create animations, create images, creating images, drawing, creating visual content, create videos, create visual content, file conversion, image editing, photo editing, video editing",
  },
];
