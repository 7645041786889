export const ResearchTr = [
  {
    id: 6001,
    name: "HARPA AI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Harpa AI Inc.",
    webUrl: "https://harpa.ai/",
    works_on:
      "Kullanıcılar, doğal dil işleme, veri analizi ve öneri sistemleri gibi yapay zeka teknolojilerini kullanarak bilgiye erişebilir, sorular sorabilir ve öneriler alabilir.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8", "6"],
  },
];

export const ResearchEn = [
  {
    id: 6001,
    name: "HARPA AI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Harpa AI Inc.",
    webUrl: "https://harpa.ai/",
    works_on:
      "Users can access information, ask questions, and receive recommendations using artificial intelligence technologies such as natural language processing, data analysis, and recommendation systems.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8", "6"],
    keywords:
      "Harpa AI, doing research, research artificial intelligence, data analysis, web automation",
  },
];
