export const GenerateTr = [
  {
    id: 11001,
    name: "Droxy AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Droxy AI Inc.",
    webUrl: "https://www.droxy.ai/",
    works_on:
      "Kullanıcıların kod yazmadan kendi chatbotlarını oluşturmalarına olanak tanıyan bir yapay zeka chatbot oluşturucusudur.",
    pricing: ["0", "1"],
    typeIcon: ["11"],
  },
];

export const GenerateEn = [
  {
    id: 11001,
    name: "Droxy AI",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Droxy AI Inc.",
    webUrl: "https://www.droxy.ai/",
    works_on:
      "It is an artificial intelligence chatbot creator that allows users to create their own chatbots without writing code.",
    pricing: ["0", "1"],
    typeIcon: ["11"],
    keywords: "Droxy AI, building artificial intelligence, chatbot, no-code AI",
  },
];
