export const CopywritingTr = [
  {
    id: 3001,
    name: "ChatGPT",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "OpenAI",
    webUrl: "https://chat.openai.com",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, özetleme yapabilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 3002,
    name: "Google Bard",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Google",
    webUrl: "https://bard.google.com",
    works_on:
      "Bilgi verir, metinleri anlar ve cevaplar üretir, içerik üretir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
  },
  {
    id: 8003,
    name: "Bing AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Microsoft",
    webUrl: "https://www.bing.com",
    works_on:
      "Metin oluşturabilir, dile çevirisi yapabilir, planlama yapabilir, öneriler verebilir, soru cevaplayabilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
  },
  {
    id: 3004,
    name: "Claude AI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Anthropic",
    webUrl: "https://claude.ai",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, özetleme yapabilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 8005,
    name: "Notion AI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Notion",
    webUrl: "https://www.notion.so/product/ai",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, verileri analiz edebilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 3006,
    name: "Jasper",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Google",
    webUrl: "https://www.jasper.ai",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, verileri analiz edebilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 8007,
    name: "OctiAI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Google",
    webUrl: "https://www.octiai.com",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, verileri analiz edebilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 3008,
    name: "Copy.ai",
    founding_year: "2020",
    type: "Doğal Dil İşleme",
    developer: "Copy AI Inc.",
    webUrl: "https://www.copy.ai",
    works_on:
      "Metin açıklamalarına göre çeşitli yaratıcı içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 8009,
    name: "HyperWrite",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "HyperWrite AI Inc.",
    webUrl: "https://www.hyperwriteai.com",
    works_on:
      "Metin açıklamalarına göre çeşitli yaratıcı içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 8011,
    name: "writesonic",
    founding_year: "2020",
    type: "Doğal Dil İşleme",
    developer: "Writesonic AI",
    webUrl: "https://writesonic.com",
    works_on:
      "İçerik oluşturmaya yardımcı olan bir yapay zeka aracıdır. Makaleler, blog gönderileri, sosyal medya gönderileri, e-posta, reklamlar ve daha fazlası gibi çeşitli içerik türleri oluşturabilir.",
    pricing: ["1"],
    typeIcon: ["3", "7", "8"],
    keywords:
      "writesonic, içerik üretme, metin oluşturma, makale oluşturma, sosyal medya gönderisi, e-posta oluşturma, reklam oluşturma, blog gönderisi oluşturma",
  },
];

export const CopywritingEn = [
  {
    id: 3001,
    name: "ChatGPT",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "OpenAI",
    webUrl: "https://chat.openai.com",
    works_on:
      "It can compose text, translate languages, help answer questions, summarize, and perform many other language processing tasks.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Chatgpt, text creation, content production, translation, question answering, natural language processing",
  },
  {
    id: 3002,
    name: "Google Bard",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Google",
    webUrl: "https://bard.google.com",
    works_on:
      "It provides information, understands texts and generates answers, generates content, and can perform many other language processing tasks.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
    keywords:
      "Googl Bard, text creation, question answering, content generation, natural language processing",
  },
  {
    id: 8003,
    name: "Bing AI",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Microsoft",
    webUrl: "https://www.bing.com",
    works_on:
      "It can compose text, translate languages, plan, give suggestions, answer questions, and perform many other language processing tasks.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
    keywords:
      "Bing AI, text creation, question answering, content generation,translation, planning, suggestion, natural language processing",
  },
  {
    id: 3004,
    name: "Claude AI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Anthropic",
    webUrl: "https://claude.ai",
    works_on:
      "It can compose text, translate languages, help answer questions, summarize, and perform many other language processing tasks.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Claude AI, text generation, content generation, translation, question answering, text summarization, natural language processing",
  },
  {
    id: 8005,
    name: "Notion AI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Notion",
    webUrl: "https://www.notion.so/product/ai",
    works_on:
      "It can compose text, translate languages, help answer questions, analyze data, and perform many more language processing tasks.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Notion AI, text creation, content generation, question answering, data analysis, translation, natural language processing",
  },
  {
    id: 3006,
    name: "Jasper",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Google",
    webUrl: "https://www.jasper.ai",
    works_on:
      "It can compose text, translate languages, help answer questions, analyze data, and perform many more language processing tasks.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
    keywords:
      "Jasper, text creation, translation, content generation, data analysis, question answering, natural language processing",
  },
  {
    id: 8007,
    name: "OctiAI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Google",
    webUrl: "https://www.octiai.com",
    works_on:
      "Can compose text, translate languages, help answer questions, analyze data, and perform many more language processing tasks.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
    keywords:
      "OctiAI, text creation, content generation, question answering, translation, natural language processing",
  },
  {
    id: 3008,
    name: "Copy.ai",
    founding_year: "2020",
    type: "Natural Language Processing",
    developer: "Copy AI Inc.",
    webUrl: "https://www.copy.ai",
    works_on:
      "It is an artificial intelligence tool that allows creating various creative content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords: "copy.ai, content generation, natural language processing",
  },
  {
    id: 8009,
    name: "HyperWrite",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "HyperWrite AI Inc.",
    webUrl: "https://www.hyperwriteai.com",
    works_on:
      "It is an artificial intelligence tool that allows creating various creative content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords: "Hyperwrite, content generation, natural language processing",
  },
  {
    id: 8011,
    name: "writesonic",
    founding_year: "2020",
    type: "Natural Language Processing",
    developer: "Writesonic AI",
    webUrl: "https://writesonic.com",
    works_on:
      "It is an artificial intelligence tool that helps create content. It can create various types of content such as articles, blog posts, social media posts, email, ads and more.",
    pricing: ["1"],
    typeIcon: ["3", "7", "8"],
    keywords:
      "writesonic, content creation, text creation, article creation, social media post, email creation, ad creation, blog post creation",
  },
];
