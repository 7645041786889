export const ImageTr = [
  {
    id: 5001,
    name: "Midjourney",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Midjourney GmbH",
    webUrl: "https://www.midjourney.com",
    works_on:
      "Metin açıklamalarına göre resimler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
  },
  {
    id: 5002,
    name: "DALL-E 2",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "OpenAI",
    webUrl: "https://openai.com/dall-e-2",
    works_on:
      "Metin açıklamalarına göre resimler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
  },
  {
    id: 5003,
    name: "DALL-E 3",
    founding_year: "2023",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "OpenAI",
    webUrl: "https://openai.com/dall-e-3",
    works_on:
      "Metin açıklamalarına göre resimler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
  },
  {
    id: 10001,
    name: "Leonardo AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Leonardo AI Inc.",
    webUrl: "https://leonardo.ai/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar ve diğer görsel içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
  },
  {
    id: 10002,
    name: "Adobe Firefly",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Adobe",
    webUrl: "https://firefly.adobe.com/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar ve diğer görsel içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
  },
  {
    id: 10003,
    name: "Runway",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Runway AI",
    webUrl: "https://runwayml.com/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar, videolar ve diğer görsel içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
  },
  {
    id: 10005,
    name: "Pictory",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Pictory AI Inc.",
    webUrl: "https://pictory.ai/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar ve videolar oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
  },
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "Görüntülerdeki nesneleri ve olayları tanımak için, metinleri analiz etmek için ve sesleri tanımak için web sitesi veya API aracılığı ile kullanılabilir. ",
    pricing: ["1"],
    typeIcon: ["1", "2", "5", "8", "10"],
  },
  {
    id: 10007,
    name: "TinyWow",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "TinyWow Teknoloji",
    webUrl: "https://tinywow.com/",
    works_on:
      "Kullanıcıların dosya dönüştürme, görsel düzenleme, video düzenleme ve yapay zeka tabanlı diğer görevleri gerçekleştirmesine olanak tanır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "8", "10"],
  },
  {
    id: 5010,
    name: "logoai",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "LOGO AI",
    webUrl: "https://www.logoai.com/",
    works_on:
      "Kullanıcıların kendi logolarını oluşturmalarına yardımcı olan bir yapay zeka aracıdır.",
    pricing: ["1"],
    typeIcon: ["5", "10"],
  },
];

export const ImageEn = [
  {
    id: 5001,
    name: "Midjourney",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Midjourney GmbH",
    webUrl: "https://www.midjourney.com",
    works_on:
      "It is an artificial intelligence tool that allows creating images based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
    keywords: "Midjourney, image creation, rendering",
  },
  {
    id: 5002,
    name: "DALL-E 2",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "OpenAI",
    webUrl: "https://openai.com/dall-e-2",
    works_on:
      "It is an artificial intelligence tool that allows creating images based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
    keywords: "dall-e 2, rendering, rendering",
  },
  {
    id: 5003,
    name: "DALL-E 3",
    founding_year: "2023",
    type: "Natural Language Processing, Image Processing",
    developer: "OpenAI",
    webUrl: "https://openai.com/dall-e-3",
    works_on:
      "It is an artificial intelligence tool that allows creating images based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
    keywords: "Dall-e 3, rendering, rendering",
  },
  {
    id: 10001,
    name: "Leonardo AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Leonardo AI Inc.",
    webUrl: "https://leonardo.ai/",
    works_on:
      "It is an artificial intelligence tool that allows it to create images, drawings, animations, and other visual content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords: "Leonardo AI, draw, create animations, create images",
  },
  {
    id: 10002,
    name: "Adobe Firefly",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Adobe",
    webUrl: "https://firefly.adobe.com/",
    works_on:
      "It is an artificial intelligence tool that allows it to create images, drawings, animations, and other visual content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords:
      "Adobe Firefly, creating images, drawing, creating visual content",
  },
  {
    id: 10003,
    name: "Runway",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Runway AI",
    webUrl: "https://runwayml.com/",
    works_on:
      "It is an artificial intelligence tool that allows it to create images, drawings, animations, videos and other visual content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords:
      "Runway, create images, draw, create videos, create visual content",
  },
  {
    id: 10005,
    name: "Pictory",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Pictory AI Inc.",
    webUrl: "https://pictory.ai/",
    works_on:
      "It is an artificial intelligence tool that allows creating images, drawings, animations and videos based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords: "Pictory, create image, draw, create drawing, create video",
  },
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "It can be used via the website or API to recognize objects and events in images, analyze texts, and recognize sounds.",
    pricing: ["1"],
    typeIcon: ["1", "2", "5", "8", "10"],
    keywords:
      "Clarif AI, image identification, photo identification, object identification, text analysis, voice recognition, video identification",
  },
  {
    id: 10007,
    name: "TinyWow",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "TinyWow Teknoloji",
    webUrl: "https://tinywow.com/",
    works_on:
      "It allows users to perform file conversion, image editing, video editing, and other AI-based tasks.",
    pricing: ["0", "1"],
    typeIcon: ["5", "8", "10"],
    keywords:
      "Tinywow, file conversion, image editing, photo editing, video editing",
  },
  {
    id: 5010,
    name: "logoai",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "LOGO AI",
    webUrl: "https://www.logoai.com/",
    works_on:
      "It is an artificial intelligence tool that helps users create their own logos.",
    pricing: ["1"],
    typeIcon: ["5", "10"],
    keywords: "logoai, logo creation, logo making",
  },
];
