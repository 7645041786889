import { useState, useEffect } from "react";
import { allAiTr } from "../context/AllAi";
import { keywordEn, keywordTr } from "../context/Keywords/Keywords";
import { useSelector, useDispatch } from "react-redux";
import { addAi, changeSearchTerm } from "../store/slices/aiSlice";
//-----components
import SearchedAi from "./SearchedAi";
//-----svg
import InfoView from "../assets/svg/infoView.svg";
import LogoView from "../assets/svg/logoView.svg";
import CardList from "../assets/svg/cardList.svg";
import CategorList from "../assets/svg/categoryList.svg";
import ShowFilter from "../assets/svg/arrowDown.svg";
import HideFilter from "../assets/svg/arrowUp.svg";
//-----icons
import ClearFilter from "../assets/gif/trash.gif";
import VoiceAIGif from "../assets/gif/1.gif";
import CodeAIGif from "../assets/gif/2.gif";
import CopywritingAIGif from "../assets/gif/3.gif";
import EducationAIGif from "../assets/gif/4.gif";
import ImageAIGif from "../assets/gif/5.gif";
import ResearchAIGif from "../assets/gif/6.gif";
import SocialMediaAIGif from "../assets/gif/7.gif";
import TextAIGif from "../assets/gif/8.gif";
import TranslationAIGif from "../assets/gif/9.gif";
import VideoAIGif from "../assets/gif/10.gif";
import GenerateAIGif from "../assets/gif/11.gif";
//-----images
import CategoryInfo from "../assets/images/others/category-info.png";
import CategoryLogo from "../assets/images/others/category-logo.png";
import ListInfo from "../assets/images/others/list-info.png";
import ListLogo from "../assets/images/others/list-logo.png";
import CategoryInfoMobil from "../assets/images/others/category-info-mobil.png";
import CategoryLogoMobil from "../assets/images/others/category-logo-mobil.png";
import ListInfoMobil from "../assets/images/others/list-info-mobil.png";
import ListLogoMobil from "../assets/images/others/list-logo-mobil.png";
//-----others
import Tooltip from "@mui/material/Tooltip";
import "./css/SearchBar.css";

import FilterArea from "./AICategoryView";

const SearchBar = ({ langPropsData, setIsSearched }) => {
  const { searchTerm } = useSelector((state) => state.ai);
  const [selectType, setSelectType] = useState();
  const [selectTypeProps, setSelectTypeProps] = useState();
  const [isSearchedLocal, setIsSearchedLocal] = useState(false);
  const [openFiltersArea, setOpenFiltersArea] = useState(false);
  const [showDatas, setShowDatas] = useState(true);
  const [list, setList] = useState("card");
  const [view, setView] = useState("logo");
  const [hideFilter, setHideFilter] = useState(false);
  const [hideView, setHideView] = useState(false);
  const [keywordLang, setKeywordLang] = useState([]);
  const [searchTermTry, setSearchTermTry] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchTerm.length > 0) {
      setIsSearchedLocal(true);
    } else {
      setIsSearchedLocal(false);
    }
    setIsSearched(isSearchedLocal);
    if (selectType !== "none") {
      setSelectType("none");
      setList("card");
    }
  }, [searchTerm, setIsSearched]);

  useEffect(() => {
    setSelectTypeProps(selectType);
  }, [selectType]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (searchTerm.length === 0) {
      allAiTr.forEach((item) => {
        dispatch(addAi({ data: item }));
      });
    }
  };

  const toggleFilterButton = (data) => {
    setSelectType(data);
    setShowDatas(true);
    // setOpenFiltersArea(false);
    setHideFilter(false);
  };

  const toggleChangeFilter = () => {
    setHideFilter(!hideFilter);
    setHideView(false);
    setShowDatas(true);
    hideFilter ? setShowDatas(true) : setShowDatas(false);
    if (list === "category") {
      setList("card");
    }
  };

  const toggleChangeView = () => {
    setHideView(!hideView);
    setHideFilter(false);
    hideView ? setShowDatas(true) : setShowDatas(false);
  };

  const applyChange = () => {
    setShowDatas(!showDatas);
    setHideFilter(false);
    setHideView(false);
  };

  const toggleViewSvg = (viewType) => {
    if (viewType === "logo") {
      setView("logo");
    } else {
      setView("info");
    }
  };

  const toggleListSvg = (listType) => {
    if (listType === "category") {
      setList("category");
    } else {
      setList("card");
    }
  };

  const handleSearcChange = (data) => {
    dispatch(changeSearchTerm(data));

    const searchValue = data.toLowerCase();
    setSearchTermTry(searchValue);

    langPropsData === "EN"
      ? setKeywordLang(keywordEn)
      : setKeywordLang(keywordTr);

    if (Array.isArray(keywordLang)) {
      const keywords = keywordLang
        .map((item) => item.keywords)
        .join(", ")
        .split(", ")
        .map((keyword) => keyword.toLowerCase());

      // Arama terimini içeren önerileri bul
      const matchingSuggestions = keywords.filter((keyword) =>
        keyword.includes(searchValue)
      );

      // Eğer arama terimi 3 veya 4 harf içeriyorsa, sadece o terimi öneri olarak göster
      const filteredSuggestions =
        searchValue.length >= 2 && searchValue.length <= 8
          ? matchingSuggestions.filter((suggestion) =>
              suggestion.startsWith(searchValue)
            )
          : [];

      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  return (
    <>
      <div className="search-comp-cover">
        <div className="suggestion-div">
          {searchTerm.length >= 3 && suggestions.length > 0
            ? langPropsData === "EN"
              ? "Suggestions: " + suggestions.join(", ")
              : "Öneriler: " + suggestions.join(", ")
            : null}
        </div>
        <div className="all-search-bar-comp">
          <div
            className={
              hideFilter
                ? "choose-type-active search-select-class-left"
                : "choose-type search-select-class-left"
            }
            onClick={() => {
              toggleChangeFilter();
            }}
          >
            <div className="view-and-list">
              {hideFilter
                ? langPropsData === "EN"
                  ? "Hide"
                  : "Gizle"
                : langPropsData === "EN"
                ? "Categories"
                : "Kategoriler"}
            </div>
            <img
              style={{ width: "30px", cursor: "pointer" }}
              src={!hideFilter ? ShowFilter : HideFilter}
              alt="Favorite AI, Favori Yapay Zekalar"
            />
          </div>
          <form className="input-class-div" action="" onSubmit={handleSubmit}>
            <Tooltip
              title={
                langPropsData === "EN"
                  ? "You can search by AI name and suggested content."
                  : "Yapay zeka adına ve önerilen içeriğe göre arama yapabilirsiniz."
              }
              placement="bottom"
            >
              <input
                disabled={openFiltersArea ? true : null}
                onChange={(e) => {
                  handleSearcChange(e.target.value);
                }}
                placeholder={
                  openFiltersArea
                    ? null
                    : langPropsData === "EN"
                    ? "Search AI"
                    : "Yapay Zeka Ara"
                }
                className="inputClass"
                type="search"
                value={searchTerm}
              />
            </Tooltip>
            <button style={{ display: "none" }} type="submit">
              Ara
            </button>
          </form>
          <div className="mobil-filters">
            <div
              className={
                hideFilter
                  ? "choose-type-active search-select-class-mobile-right"
                  : "choose-type search-select-class-mobile-right"
              }
              onClick={() => {
                toggleChangeFilter();
              }}
            >
              <div className="view-and-list">
                {hideFilter
                  ? langPropsData === "EN"
                    ? "Hide"
                    : "Gizle"
                  : langPropsData === "EN"
                  ? "Categories"
                  : "Kategoriler"}
              </div>
              <img
                style={{ width: "30px", cursor: "pointer" }}
                src={!hideFilter ? ShowFilter : HideFilter}
                alt="Favorite AI, Favori Yapay Zekalar"
              />
            </div>
            <div
              className={
                hideView
                  ? "choose-view-active search-select-class-right"
                  : "choose-view search-select-class-right"
              }
              onClick={() => {
                toggleChangeView();
              }}
            >
              <div className="view-and-list">
                {hideView
                  ? langPropsData === "EN"
                    ? "Close"
                    : "Kapat"
                  : langPropsData === "EN"
                  ? "Views & List"
                  : "Görünüm-Listeleme"}
              </div>
              <img
                style={{ width: "30px", cursor: "pointer" }}
                src={!hideView ? ShowFilter : HideFilter}
                alt="Favorite AI, Favori Yapay Zekalar"
              />
            </div>
          </div>
          <div
            className={
              hideView
                ? "choose-view-active close-mobile-screen"
                : "choose-view close-mobile-screen"
            }
            onClick={() => {
              toggleChangeView();
            }}
          >
            <div style={{ cursor: "pointer" }}>
              {hideView
                ? langPropsData === "EN"
                  ? "Close"
                  : "Kapat"
                : langPropsData === "EN"
                ? "Views & List"
                : "Listeleme"}
            </div>
            <img
              style={{ width: "30px", cursor: "pointer" }}
              src={!hideView ? ShowFilter : HideFilter}
              alt="Favorite AI, Favori Yapay Zekalar"
            />
          </div>
        </div>
        {!openFiltersArea ? (
          <>
            <div className="filter-area-top"></div>
            {hideFilter ? (
              <>
                <div className="filtersArea">
                  <div
                    onClick={() => {
                      toggleFilterButton("none");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={ClearFilter}
                      alt="AI Detailse, About AI, Yapay Zeka detayları, Yapay Zeka Hakkında"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN"
                        ? "Clear Filter"
                        : "Filtreyi Temizle"}
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      toggleFilterButton("1");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={VoiceAIGif}
                      alt="Voice AI, AI for Voice, texttospeech AI, Ses için Yapay Zeka, sesten metine yapay zeka"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN" ? "Audio" : "Ses"}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toggleFilterButton("2");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={CodeAIGif}
                      alt="Code AI, AI for code, AI for programming, Code için Yapay Zeka"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN" ? "Code" : "Kod"}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toggleFilterButton("3");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={CopywritingAIGif}
                      alt="Copywriting AI, AI for Copywriting, Metin Yazarlığı YZ, Metin yazarlığı için Yapay Zeka"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN"
                        ? "Copywriting"
                        : "Metin Yazarlığı"}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toggleFilterButton("4");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={EducationAIGif}
                      alt="Education AI, AI for Education, Eğitim YZ, Eğitim için Yapay Zeka"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN" ? "Education" : "Eğitim"}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toggleFilterButton("5");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={ImageAIGif}
                      alt="Image AI, AI for Image, AI for Picture, Resim YZ, Resim için Yapay Zeka, Fotoğraf için Yapay Zeka"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN" ? "Image" : "Resim"}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toggleFilterButton("6");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={ResearchAIGif}
                      alt="Research AI, AI for Research, Araştırma YZ, Araştırma için Yapay Zeka"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN" ? "Research" : "Araştırma"}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toggleFilterButton("7");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={SocialMediaAIGif}
                      alt="Social Media AI, AI for Social Media, Sosyal Medya YZ, Sosyal Medya için Yapay Zeka"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN" ? "Social Media" : "Sosyal Medya"}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toggleFilterButton("8");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={TextAIGif}
                      alt="Text AI, Chat AI, AI for Text, AI for Chat, Metin Tabanlı YZ, Metin için Yapay Zeka"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN" ? "Text" : "Metin"}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toggleFilterButton("9");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={TranslationAIGif}
                      alt="Translation AI, AI for Translation, Natural language translation, Çeviri YZ, Çeviri için Yapay Zeka ,Çeviri için Yapay Zeka, doğal dil çevirisi"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN" ? "Translation" : "Çeviri"}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toggleFilterButton("10");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={VideoAIGif}
                      alt="Video AI, AI for Video, Video YZ, Video için Yapay Zeka, Video düzenleme Yapay Zeka"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN" ? "Video" : "Video"}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      toggleFilterButton("11");
                    }}
                    className="filter-card"
                  >
                    <img
                      className="filter-area-icons"
                      src={GenerateAIGif}
                      alt="Generate AI, AI Generate, make AI, YZ Oluşturma, YZ yapmak, Yapay Zeka oluşturmak, Yapay Zeka bot, Yapay Zeka üretmek"
                    />
                    <div className="filter-card-type-text">
                      {langPropsData === "EN" ? "Generate AI" : "AI Oluşturucu"}
                    </div>
                  </div>
                </div>
              </>
            ) : hideView ? (
              <>
                <div className="list-view-class">
                  <div className="list-view-content">
                    <div className="list-view-content-title">
                      {langPropsData === "EN"
                        ? "Change Card View"
                        : "Kart Görünümü"}
                    </div>
                    <div className="flex-space-around">
                      <div>
                        <img
                          className={
                            view === "info"
                              ? "list-view-svg-class active-svg"
                              : "list-view-svg-class"
                          }
                          src={InfoView}
                          onClick={() => {
                            toggleViewSvg("info");
                          }}
                          alt=""
                        />
                        <div>
                          {langPropsData === "EN"
                            ? "Info View"
                            : "Bilgi Görünümü"}
                        </div>
                      </div>
                      <div>
                        <img
                          className={
                            view === "logo"
                              ? "list-view-svg-class active-svg"
                              : "list-view-svg-class"
                          }
                          src={LogoView}
                          onClick={() => {
                            toggleViewSvg("logo");
                          }}
                          alt=""
                        />
                        <div>
                          {langPropsData === "EN"
                            ? "Logo View"
                            : "Logo Görünümü"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="list-view-content web-display">
                    <div className="list-view-content-title">
                      {langPropsData === "EN" ? "Preview" : "Önizleme"}
                    </div>
                    <div>
                      <img
                        style={{ width: "100%" }}
                        src={
                          view === "info" && list === "card"
                            ? ListInfo
                            : view === "info" && list === "category"
                            ? CategoryInfo
                            : view === "logo" && list === "card"
                            ? ListLogo
                            : view === "logo" && list === "category"
                            ? CategoryLogo
                            : null
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="list-view-content">
                    <div className="list-view-content-title">
                      {langPropsData === "EN"
                        ? "Change List View"
                        : "Listeleme Görünümü"}
                    </div>
                    <div className="flex-space-around">
                      <div>
                        <img
                          className={
                            list === "card"
                              ? "list-view-svg-class active-svg"
                              : "list-view-svg-class"
                          }
                          src={CardList}
                          onClick={() => {
                            toggleListSvg("card");
                          }}
                          alt=""
                        />
                        <div>{langPropsData === "EN" ? "Card" : "Kart"}</div>
                      </div>
                      <div>
                        <img
                          className={
                            list === "category"
                              ? "list-view-svg-class active-svg"
                              : "list-view-svg-class"
                          }
                          src={CategorList}
                          onClick={() => {
                            toggleListSvg("category");
                          }}
                          alt=""
                        />
                        <div>
                          {langPropsData === "EN"
                            ? "Category List"
                            : "Kategori Listesi"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="list-view-content mobile-display">
                  <div className="mobile-view-class">
                    <img
                      className="mobile-view-class-img"
                      src={
                        view === "info" && list === "card"
                          ? ListInfoMobil
                          : view === "info" && list === "category"
                          ? CategoryInfoMobil
                          : view === "logo" && list === "card"
                          ? ListLogoMobil
                          : view === "logo" && list === "category"
                          ? CategoryLogoMobil
                          : null
                      }
                      alt=""
                    />
                  </div>
                </div>
                <button onClick={applyChange} className="apply-filters-btn">
                  {langPropsData === "EN" ? "Apply" : "Uygula"}
                </button>
              </>
            ) : null}
          </>
        ) : null}
      </div>
      {showDatas ? (
        list === "category" ? (
          <>
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="1"
              langPropsData={langPropsData}
            />
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="2"
              langPropsData={langPropsData}
            />
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="3"
              langPropsData={langPropsData}
            />
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="4"
              langPropsData={langPropsData}
            />
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="5"
              langPropsData={langPropsData}
            />
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="6"
              langPropsData={langPropsData}
            />
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="7"
              langPropsData={langPropsData}
            />
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="8"
              langPropsData={langPropsData}
            />
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="9"
              langPropsData={langPropsData}
            />
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="10"
              langPropsData={langPropsData}
            />
            <FilterArea
              cardTypeProps={view}
              typeNumberProps="11"
              langPropsData={langPropsData}
            />
          </>
        ) : (
          <>
            <div className="ai-list-title">
              {selectType === "1"
                ? langPropsData === "EN"
                  ? "VOICE ARTIFICIAL INTELLIGENCES"
                  : "SES YAPAY ZEKALARI"
                : selectType === "2"
                ? langPropsData === "EN"
                  ? "CODE-BASED ARTIFICIAL INTELLIGENCES"
                  : "KOD YAPAY ZEKALARI"
                : selectType === "3"
                ? langPropsData === "EN"
                  ? "COPYWRITING ARTIFICIAL INTELLIGENCES"
                  : "METTİN YAZARLIĞI YAPAY ZEKALARI"
                : selectType === "4"
                ? langPropsData === "EN"
                  ? "EDUCATION-BASED ARTIFICIAL INTELLIGENCES"
                  : "EĞİTİM YAPAY ZEKALARI"
                : selectType === "5"
                ? langPropsData === "EN"
                  ? "IMAGE-BASED ARTIFICIAL INTELLIGENCES"
                  : "RESİM YAPAY ZEKALARI"
                : selectType === "6"
                ? langPropsData === "EN"
                  ? "RESEARCH-BASED ARTIFICIAL INTELLIGENCES"
                  : "ARAŞTIRMA YAPAY ZEKALARI"
                : selectType === "7"
                ? langPropsData === "EN"
                  ? "SOCIAL MEDIA-BASED ARTIFICIAL INTELLIGENCES"
                  : "SOSYAL MEDYA YAPAY ZEKALARI"
                : selectType === "8"
                ? langPropsData === "EN"
                  ? "TEXT-BASED ARTIFICIAL INTELLIGENCES"
                  : "METİN TABANLI YAPAY ZEKALAR"
                : selectType === "9"
                ? langPropsData === "EN"
                  ? "TRANSLATION-BASED ARTIFICIAL INTELLIGENCES"
                  : "ÇEVİRİ YAPAY ZEKALARI"
                : selectType === "10"
                ? langPropsData === "EN"
                  ? "VIDEO-BASED ARTIFICIAL INTELLIGENCES"
                  : "VİDEO YAPAY ZEKALARI"
                : selectType === "11"
                ? langPropsData === "EN"
                  ? "ARTIFICIAL INTELLIGENCES GENERATE"
                  : "YAPAY ZEKA OLUŞTURUCU"
                : selectType === "none"
                ? langPropsData === "EN"
                  ? "ALL ARTIFICIAL INTELLIGENCES"
                  : "TÜM YAPAY ZEKALAR"
                : langPropsData === "EN"
                ? "ALL ARTIFICIAL INTELLIGENCES"
                : "TÜM YAPAY ZEKALAR"}
            </div>
            <SearchedAi
              // propsDetail={getDetails}
              propsSelect={selectTypeProps}
              datasLanguage={langPropsData}
              cardTypes={view}
              setIsSearched={isSearchedLocal}
            />
          </>
        )
      ) : null}
    </>
  );
};

export default SearchBar;

// audio        1
// code         2
// copywriting  3
// education    4
// image        5
// research     6
// socialmedia  7
// text         8
// translation  9
// video        10
// generate     11
