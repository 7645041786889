import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//-----Get Datas
import { allAiTr, allAiEn } from "../context/AllAi";
import { AudioEn, AudioTr } from "../context/Types/Audio";
import { CodeEn, CodeTr } from "../context/Types/Code";
import { CopywritingEn, CopywritingTr } from "../context/Types/Copywriting";
import { EducationEn, EducationTr } from "../context/Types/Education";
import { GenerateEn, GenerateTr } from "../context/Types/Generate";
import { ImageEn, ImageTr } from "../context/Types/Image";
import { ResearchEn, ResearchTr } from "../context/Types/Research";
import { SocialMediaEn, SocialMediaTr } from "../context/Types/SocialMedia";
import { TextEn, TextTr } from "../context/Types/Text";
import { TranslationEn, TranslationTr } from "../context/Types/Translation";
import { VideoEn, VideoTr } from "../context/Types/Video";
//-----Others
import Card from "./Card";
import "./css/SearchedAi.css";

// const SearchedAi = ({ propsDetail, propsSelect, datasLanguage, cardTypes }) => {
const SearchedAi = ({
  propsSelect,
  datasLanguage,
  cardTypes,
  setIsSearched,
}) => {
  // const [details, setDetails] = useState(propsDetail);
  const { searchTerm } = useSelector((state) => state.ai);
  const [filteredAiData, setFilteredAiData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [showMoreButton, setShowMoreButton] = useState(false);

  const itemsPerPage = 18;

  useEffect(() => {
    const initialData = datasLanguage === "EN" ? allAiEn : allAiTr;
    setFilteredAiData(initialData);
    // const newDataSlice = initialData.slice(offset, offset + itemsPerPage);
    // setFilteredAiData((prevData) => [...prevData, ...newDataSlice]);

    if (searchTerm.length > 0) {
      const filteredData = initialData.filter((item) =>
        item.keywords.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredAiData(filteredData);
    }
    if (filteredAiData.length >= offset) {
      setShowMoreButton(true);
    } else {
      setShowMoreButton(false);
    }
  }, [datasLanguage, searchTerm, offset]);

  // useEffect(() => {
  //   setDetails(propsDetail);
  // }, [propsDetail]);

  
  useEffect(() => {
    if (propsSelect === "none") {
      setSelectedData([]);
    } else if (propsSelect === "1") {
      datasLanguage === "EN"
        ? setSelectedData(AudioEn)
        : setSelectedData(AudioTr);
    } else if (propsSelect === "2") {
      datasLanguage === "EN"
        ? setSelectedData(CodeEn)
        : setSelectedData(CodeTr);
    } else if (propsSelect === "3") {
      datasLanguage === "EN"
        ? setSelectedData(CopywritingEn)
        : setSelectedData(CopywritingTr);
    } else if (propsSelect === "4") {
      datasLanguage === "EN"
        ? setSelectedData(EducationEn)
        : setSelectedData(EducationTr);
    } else if (propsSelect === "5") {
      datasLanguage === "EN"
        ? setSelectedData(ImageEn)
        : setSelectedData(ImageTr);
    } else if (propsSelect === "6") {
      datasLanguage === "EN"
        ? setSelectedData(ResearchEn)
        : setSelectedData(ResearchTr);
    } else if (propsSelect === "7") {
      datasLanguage === "EN"
        ? setSelectedData(SocialMediaEn)
        : setSelectedData(SocialMediaTr);
    } else if (propsSelect === "8") {
      datasLanguage === "EN"
        ? setSelectedData(TextEn)
        : setSelectedData(TextTr);
    } else if (propsSelect === "9") {
      datasLanguage === "EN"
        ? setSelectedData(TranslationEn)
        : setSelectedData(TranslationTr);
    } else if (propsSelect === "10") {
      datasLanguage === "EN"
        ? setSelectedData(VideoEn)
        : setSelectedData(VideoTr);
    } else if (propsSelect === "11") {
      datasLanguage === "EN"
        ? setSelectedData(GenerateEn)
        : setSelectedData(GenerateTr);
    }
  }, [propsSelect, datasLanguage]);

  const handleMoreClick = () => {
    setOffset(offset + itemsPerPage);
  };

  return (
    <div className="searched-ai-results">
      <div className="searched-ai-cover">
        <div className="searched-ai-content">
          {selectedData.length > 0 ? (
            selectedData.map((selectedDataItem) => (
              <Card
                key={selectedDataItem.id}
                aiData={selectedDataItem}
                langPropsData={datasLanguage}
                // showDetailProps={details}
                cardTypesProps={cardTypes}
              />
            ))
          ) : filteredAiData.length > 0 ? (
            filteredAiData.map((aiDataItem) => (
              <Card
                key={aiDataItem.id}
                aiData={aiDataItem}
                langPropsData={datasLanguage}
                // showDetailProps={details}
                cardTypesProps={cardTypes}
              />
            ))
          ) : (
            <div style={{ color: "white" }}>
              {datasLanguage === "EN"
                ? `No AI name or content related to '${searchTerm}' found!`
                : `'${searchTerm}' ile ilgili bir yapay zeka adı ya da içeriği bulunamadı!`}
            </div>
          )}
        </div>
      </div>
      {/* {(propsSelect === "none" || propsSelect === undefined) &&
      !setIsSearched ? (
        showMoreButton ? (
          <button className="more-ai-button" onClick={handleMoreClick}>
            Daha Fazla Göster
          </button>
        ) : (
          <div style={{ color: "white", textAlign: "center" }}>
            {datasLanguage === "EN"
              ? "All artificial intelligences viewed."
              : "Tüm yapay zekalar görüntülendi."}
          </div>
        )
      ) : null} */}
    </div>
  );
};

export default SearchedAi;
