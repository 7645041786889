export const AudioTr = [
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "Görüntülerdeki nesneleri ve olayları tanımak için, metinleri analiz etmek için ve sesleri tanımak için web sitesi veya API aracılığı ile kullanılabilir. ",
    pricing: ["0", "1"],
    typeIcon: ["1", "2", "5", "8", "10"],
    keywords:
      "Clarif AI, görüntü tanımlama, fotoğraf tanımlama, nesne tanımlama, metin analizi, ses tanıma, video tanımlama",
  },
  {
    id: 1002,
    name: "Descript",
    founding_year: "2018",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Descript",
    webUrl: "https://www.descript.com/",
    works_on:
      "Görüntülerdeki nesneleri ve olayları tanımak için, metinleri analiz etmek için ve sesleri tanımak için web sitesi veya API aracılığı ile kullanılabilir. Ses ve videoları metin haline getirebilir. ",
    pricing: ["0", "1"],
    typeIcon: ["1", "8"],
    keywords:
      "Descript, görüntü tanımlama, fotoğraf tanımlama, nesne tanımlama, metin analizi, ses tanıma, video tanımlama, sesten metin haline dönüştürme",
  },
  {
    id: 1003,
    name: "Sonix AI",
    founding_year: "2017",
    type: "Doğal Dil İşleme",
    developer: "Sonix Inc.",
    webUrl: "https://sonix.ai/",
    works_on:
      "Ses ve video dosyalarını metne dönüştürmek için kullanılan bir araçtır.",
    pricing: ["0", "1"],
    typeIcon: ["1"],
    keywords:
      "Sonix AI, sesten metine dönüştürme, sesten metne dönüştürme, videodan metin oluşturma",
  },
  {
    id: 6001,
    name: "HARPA AI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Harpa AI Inc.",
    webUrl: "https://harpa.ai/",
    works_on:
      "Kullanıcılar, doğal dil işleme, veri analizi ve öneri sistemleri gibi yapay zeka teknolojilerini kullanarak bilgiye erişebilir, sorular sorabilir ve öneriler alabilir.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8", "6"],
    keywords:
      "Harpa AI, araştırma yapma, araştırma yapay zeka, veri analizi, web otomasyon",
  },
  {
    id: 1005,
    name: "Heygen AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "HEYGEN AI Inc.",
    webUrl: "https://www.heygen.com/",
    works_on:
      "Metin açıklamalarına göre videolar, animasyonlar ve ses dosyaları oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["1", "10"],
    keywords: "Heygen AI, animasyon oluşturma, video oluşturma, ses oluşturma",
  },
];

export const AudioEn = [
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "It can be used via the website or API to recognize objects and events in images, analyze texts, and recognize sounds.",
    pricing: ["0", "1"],
    typeIcon: ["1", "2", "5", "8", "10"],
    keywords:
      "Clarif AI, image identification, photo identification, object identification, text analysis, voice recognition, video identification",
  },
  {
    id: 1002,
    name: "Descript",
    founding_year: "2018",
    type: "Natural Language Processing, Image Processing",
    developer: "Descript",
    webUrl: "https://www.descript.com/",
    works_on:
      "It can be used via the website or API to recognize objects and events in images, analyze texts, and recognize sounds. It can convert audio and videos into text.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8"],
    keywords:
      "Descript, image identification, photo identification, object identification, text analysis, voice recognition, video identification, audio to text conversion",
  },
  {
    id: 1003,
    name: "Sonix AI",
    founding_year: "2017",
    type: "Natural Language Processing",
    developer: "Sonix Inc.",
    webUrl: "https://sonix.ai/",
    works_on: "It is a tool used to convert audio and video files to text.",
    pricing: ["0", "1"],
    typeIcon: ["1"],
    keywords:
      "Sonix AI, convert audio to text, convert audio to text, create text from video",
  },
  {
    id: 6001,
    name: "HARPA AI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Harpa AI Inc.",
    webUrl: "https://harpa.ai/",
    works_on:
      "Users can access information, ask questions, and receive recommendations using artificial intelligence technologies such as natural language processing, data analysis, and recommendation systems.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8", "6"],
    keywords:
      "Harpa AI, doing research, research artificial intelligence, data analysis, web automation",
  },
  {
    id: 1005,
    name: "Heygen AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "HEYGEN AI Inc.",
    webUrl: "https://www.heygen.com/",
    works_on:
      "It is an artificial intelligence tool that allows it to generate videos, animations, and audio files based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["1", "10"],
    keywords: "Heygen AI, create animation, create video, create audio",
  },
];
