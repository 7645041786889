import { useEffect, useState } from "react";
import { favoryAI } from "../context/Favories/FavoriesAi";
import Carousel from "react-multi-carousel";
import Card from "./Card";
import "react-multi-carousel/lib/styles.css";
//-----Ai Logos
import * as AILogos from "../helpers/AiLogos";
//-----Icons & Svg
import Close from "../assets/svg/close.svg";
import Full from "../assets/svg/link.svg";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
//-----Get Datas
import { allAiTr, allAiEn } from "../context/AllAi";
import { AudioEn, AudioTr } from "../context/Types/Audio";
import { CodeEn, CodeTr } from "../context/Types/Code";
import { CopywritingEn, CopywritingTr } from "../context/Types/Copywriting";
import { EducationEn, EducationTr } from "../context/Types/Education";
import { GenerateEn, GenerateTr } from "../context/Types/Generate";
import { ImageEn, ImageTr } from "../context/Types/Image";
import { ResearchEn, ResearchTr } from "../context/Types/Research";
import { SocialMediaEn, SocialMediaTr } from "../context/Types/SocialMedia";
import { TextEn, TextTr } from "../context/Types/Text";
import { TranslationEn, TranslationTr } from "../context/Types/Translation";
import { VideoEn, VideoTr } from "../context/Types/Video";

import Type1 from "../assets/images/aiTypes/1.png";
import Type2 from "../assets/images/aiTypes/2.png";
import Type3 from "../assets/images/aiTypes/3.png";
import Type4 from "../assets/images/aiTypes/4.png";
import Type5 from "../assets/images/aiTypes/5.png";
import Type6 from "../assets/images/aiTypes/6.png";
import Type7 from "../assets/images/aiTypes/7.png";
import Type8 from "../assets/images/aiTypes/8.png";
import Type9 from "../assets/images/aiTypes/9.png";
import Type10 from "../assets/images/aiTypes/10.png";
import Type11 from "../assets/images/aiTypes/11.png";

import "./css/AiCategoryView.css";
const aiTypeIcons = [
  Type1,
  Type2,
  Type3,
  Type4,
  Type5,
  Type6,
  Type7,
  Type8,
  Type9,
  Type10,
  Type11,
];

function AICategoryView({ cardTypeProps, langPropsData, typeNumberProps }) {
  const [hideCarousel, setHideCarouse] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [typeMenuData, setTypeMenuData] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.scrollY > window.innerHeight &&
        window.scrollY <= window.innerHeight * 2
      ) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (typeNumberProps === "none") {
      setTypeMenuData([]);
    } else if (typeNumberProps === "1") {
      langPropsData === "EN"
        ? setTypeMenuData(AudioEn)
        : setTypeMenuData(AudioTr);
    } else if (typeNumberProps === "2") {
      langPropsData === "EN"
        ? setTypeMenuData(CodeEn)
        : setTypeMenuData(CodeTr);
    } else if (typeNumberProps === "3") {
      langPropsData === "EN"
        ? setTypeMenuData(CopywritingEn)
        : setTypeMenuData(CopywritingTr);
    } else if (typeNumberProps === "4") {
      langPropsData === "EN"
        ? setTypeMenuData(EducationEn)
        : setTypeMenuData(EducationTr);
    } else if (typeNumberProps === "5") {
      langPropsData === "EN"
        ? setTypeMenuData(ImageEn)
        : setTypeMenuData(ImageTr);
    } else if (typeNumberProps === "6") {
      langPropsData === "EN"
        ? setTypeMenuData(ResearchEn)
        : setTypeMenuData(ResearchTr);
    } else if (typeNumberProps === "7") {
      langPropsData === "EN"
        ? setTypeMenuData(SocialMediaEn)
        : setTypeMenuData(SocialMediaTr);
    } else if (typeNumberProps === "8") {
      langPropsData === "EN"
        ? setTypeMenuData(TextEn)
        : setTypeMenuData(TextTr);
    } else if (typeNumberProps === "9") {
      langPropsData === "EN"
        ? setTypeMenuData(TranslationEn)
        : setTypeMenuData(TranslationTr);
    } else if (typeNumberProps === "10") {
      langPropsData === "EN"
        ? setTypeMenuData(VideoEn)
        : setTypeMenuData(VideoTr);
    } else if (typeNumberProps === "11") {
      langPropsData === "EN"
        ? setTypeMenuData(GenerateEn)
        : setTypeMenuData(GenerateTr);
    }
  }, [typeNumberProps, langPropsData]);

  const handleClose = () => setOpen(false);

  const handleOpen = (data) => {
    setOpen(true);
    setModalData(data);
  };

  const responsive = {
    desktopLarge: {
      breakpoint: { max: 3000, min: 1500 },
      items: 6,
      slidesToSlide: 3,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 5,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 4,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 3,
      slidesToSlide: 2,
    },
  };

  return (
    <div
      className="carousel-cover-for-menu"
      id={
        typeMenuData.length < 6
          ? "carousel-cover-for-menu-mobil"
          : "carousel-cover-for-menu"
      }
    >
      {hideCarousel ? null : (
        <>
          <div className="menu-h1-class">
            {typeNumberProps === "1"
              ? langPropsData === "EN"
                ? "VOICE ARTIFICIAL INTELLIGENCES"
                : "SES YAPAY ZEKALARI"
              : typeNumberProps === "2"
              ? langPropsData === "EN"
                ? "CODE-BASED ARTIFICIAL INTELLIGENCES"
                : "KOD YAPAY ZEKALARI"
              : typeNumberProps === "3"
              ? langPropsData === "EN"
                ? "COPYWRITING ARTIFICIAL INTELLIGENCES"
                : "METTİN YAZARLIĞI YAPAY ZEKALARI"
              : typeNumberProps === "4"
              ? langPropsData === "EN"
                ? "EDUCATION-BASED ARTIFICIAL INTELLIGENCES"
                : "EĞİTİM YAPAY ZEKALARI"
              : typeNumberProps === "5"
              ? langPropsData === "EN"
                ? "IMAGE-BASED ARTIFICIAL INTELLIGENCES"
                : "RESİM YAPAY ZEKALARI"
              : typeNumberProps === "6"
              ? langPropsData === "EN"
                ? "RESEARCH-BASED ARTIFICIAL INTELLIGENCES"
                : "ARAŞTIRMA YAPAY ZEKALARI"
              : typeNumberProps === "7"
              ? langPropsData === "EN"
                ? "SOCIAL MEDIA-BASED ARTIFICIAL INTELLIGENCES"
                : "SOSYAL MEDYA YAPAY ZEKALARI"
              : typeNumberProps === "8"
              ? langPropsData === "EN"
                ? "TEXT-BASED ARTIFICIAL INTELLIGENCES"
                : "METİN TABANLI YAPAY ZEKALAR"
              : typeNumberProps === "9"
              ? langPropsData === "EN"
                ? "TRANSLATION-BASED ARTIFICIAL INTELLIGENCES"
                : "ÇEVİRİ YAPAY ZEKALARI"
              : typeNumberProps === "10"
              ? langPropsData === "EN"
                ? "VIDEO-BASED ARTIFICIAL INTELLIGENCES"
                : "VİDEO YAPAY ZEKALARI"
              : typeNumberProps === "11"
              ? langPropsData === "EN"
                ? "ARTIFICIAL INTELLIGENCES GENERATE"
                : "YAPAY ZEKA OLUŞTURUCU"
              : typeNumberProps === "none"
              ? langPropsData === "EN"
                ? "ALL ARTIFICIAL INTELLIGENCES"
                : "TÜM YAPAY ZEKALAR"
              : langPropsData === "EN"
              ? "ALL ARTIFICIAL INTELLIGENCES"
              : "TÜM YAPAY ZEKALAR"}
          </div>
          {cardTypeProps === "logo" ? (
            <Carousel
              swipeable={true}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={false}
              autoPlay={false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              containerClassName="carousel-container-for-menu"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClassName="custom-dot-list-style"
              itemClassName="carousel-item"
            >
              {typeMenuData.map((aiData) => (
                <div className="cardAILogoForMenu" key={aiData.id}>
                  {AILogos[`Img${aiData.id}`] ? null : (
                    <a
                      onClick={() => handleOpen(aiData)}
                      className="none-logo-ai-name"
                    >
                      {aiData.name}
                    </a>
                  )}
                  <div
                    className="logo-card-cover"
                    onClick={() => handleOpen(aiData)}
                    target="blank"
                  >
                    <Tooltip
                      title={
                        langPropsData === "EN"
                          ? `Visit ${aiData.name}`
                          : "Siteye Git"
                      }
                      placement="top"
                      alt={aiData.keywords}
                    >
                      <img
                        src={AILogos[`Img${aiData.id}`] || AILogos.DefaultLogo}
                        alt={`Image${aiData.id}`}
                      />
                    </Tooltip>
                  </div>
                  <div className="menu-logos-under-div">
                    <div>
                      {aiData && aiData.typeIcon ? (
                        aiData.typeIcon.slice(0, 3).map((aiTypeIcon, index) => {
                          const iconIndex = parseInt(aiTypeIcon) - 1;
                          return (
                            <img
                              key={aiTypeIcon} // Bu satırı ekledik
                              onClick={() => handleOpen(aiData)}
                              className="logo-under-icons-in-card"
                              src={aiTypeIcons[iconIndex]}
                              alt="Types of AI, Types of Artifical Intelligence, AI usage areas, Yapay Zeka Türleri, AI Türleri, Yapay Zeka kullanım alanları"
                            />
                          );
                        })
                      ) : (
                        <p style={{ fontSize: "10px" }}>No icons available</p>
                      )}
                    </div>
                    <a href={aiData.webUrl} target="blank">
                      <img
                        className="modal-open-svg-for-logo"
                        // onClick={() => handleOpen(aiData)}
                        src={Full}
                        alt="Yapay Zeka,Yapay Zeka Nedir?, Yapay Zeka hakkında, Artifical Intelligence, Ai, Ai Info"
                      />
                    </a>
                  </div>
                </div>
              ))}
            </Carousel>
          ) : (
            <Carousel
              swipeable={true}
              draggable={false}
              showDots={false}
              responsive={responsive}
              ssr={true}
              infinite={false}
              autoPlay={false}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              containerClassName="carousel-container-for-menu"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClassName="custom-dot-list-style"
              itemClassName="carousel-item"
            >
              {typeMenuData.map((aiData) => (
                //   <Card
                //   // key={aiDataItem.id}
                //   // aiData={aiDataItem}
                //   // langPropsData={datasLanguage}
                //   // showDetailProps={details}
                // />
                <div
                  className={"card card-cover"}
                  onClick={() => handleOpen(aiData)}
                  target="blank"
                >
                  <div className="card-type-icons">
                    {aiData && aiData.typeIcon ? (
                      aiData.typeIcon.slice(0, 3).map((aiTypeIcon, index) => {
                        const iconIndex = parseInt(aiTypeIcon) - 1;
                        return (
                          <Tooltip
                            key={index}
                            title={
                              aiTypeIcon === "1"
                                ? langPropsData === "EN"
                                  ? "Audio"
                                  : "Ses"
                                : aiTypeIcon === "2"
                                ? langPropsData === "EN"
                                  ? "Code"
                                  : "Kod"
                                : aiTypeIcon === "3"
                                ? langPropsData === "EN"
                                  ? "Copywriting"
                                  : "Metin Yazarlığı"
                                : aiTypeIcon === "4"
                                ? langPropsData === "EN"
                                  ? "Education"
                                  : "Eğitim"
                                : aiTypeIcon === "5"
                                ? langPropsData === "EN"
                                  ? "Image"
                                  : "Resim"
                                : aiTypeIcon === "6"
                                ? langPropsData === "EN"
                                  ? "Research"
                                  : "Araştırma"
                                : aiTypeIcon === "7"
                                ? langPropsData === "EN"
                                  ? "Social Media"
                                  : "Sosyal Medya"
                                : aiTypeIcon === "8"
                                ? langPropsData === "EN"
                                  ? "Text"
                                  : "Metin"
                                : aiTypeIcon === "9"
                                ? langPropsData === "EN"
                                  ? "Translation"
                                  : "Çeviri"
                                : aiTypeIcon === "10"
                                ? langPropsData === "EN"
                                  ? "Video"
                                  : "Video"
                                : aiTypeIcon === "11"
                                ? langPropsData === "EN"
                                  ? "Generate AI"
                                  : "AI Üretmek"
                                : null
                            }
                            alt={aiData.keywords}
                          >
                            <img
                              key={aiTypeIcon} // Bu satırı ekledik
                              className="icons-in-card"
                              src={aiTypeIcons[iconIndex]}
                              alt="Types of AI, Types of Artifical Intelligence, AI usage areas, Yapay Zeka Türleri, AI Türleri, Yapay Zeka kullanım alanları"
                            />
                          </Tooltip>
                        );
                      })
                    ) : (
                      <p>No icons available</p>
                    )}
                  </div>
                  <div>
                    <Tooltip
                      title={
                        langPropsData === "EN" ? "Developer" : "Geliştirici"
                      }
                      placement="top"
                      alt={aiData.keywords}
                    >
                      <div className="card-ai-developer">
                        {aiData.developer}
                      </div>
                    </Tooltip>
                    <div className="tags">
                      <div className="card-ai-name">{aiData.name}</div>
                      <button
                        style={{ background: "none", border: "none" }}
                      ></button>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="modal-content">
              <div>
                <div className="modal-header">
                  <h2 alt={modalData.name}>{modalData.name}</h2>
                  <img
                    className="modal-close-svg"
                    onClick={handleClose}
                    src={Close}
                    alt="AI, Yapay Zeka"
                  />
                </div>
                <div className="modal-body">
                  <div className="modal-body-both">
                    <div className="modal-body-left-title">Geliştirici</div>
                    <div>:</div>
                    <div className="modal-body-left-value">
                      {modalData.developer}
                    </div>
                  </div>
                  <div className="modal-body-both">
                    <div className="modal-body-left-title">Alanı</div>
                    <div>:</div>
                    <div className="modal-body-left-value">
                      {modalData.type}
                    </div>
                  </div>
                  <div className="modal-body-both">
                    <div className="modal-body-left-title">Web sitesi</div>
                    <div>:</div>
                    <div className="modal-body-left-value">
                      <a
                        target="blank"
                        style={{
                          textDecoration: "none",
                          color: "wheat",
                        }}
                        href={modalData.webUrl ? modalData.webUrl : "#"}
                      >
                        {modalData.webUrl
                          ? modalData.webUrl
                          : langPropsData === "EN"
                          ? "Undifined"
                          : "Belirtilmemiş"}
                      </a>
                    </div>
                  </div>
                  <div className="modal-body-both">
                    <div className="modal-body-left-title">Çıkış Tarihi</div>
                    <div>:</div>
                    <div className="modal-body-left-value">
                      {modalData.founding_year}
                    </div>
                  </div>
                  <div className="modal-body-both">
                    <div className="modal-body-left-title">Fiyat</div>
                    <div>:</div>
                    <div className="modal-body-left-value">
                      {modalData.pricing &&
                      modalData.pricing.length !== undefined ? (
                        modalData.pricing.length < 2 ? (
                          modalData.pricing.map((priceData, index) => (
                            <div className="price-ai" key={index}>
                              {priceData === "0"
                                ? langPropsData === "EN"
                                  ? "Free"
                                  : "Ücretsiz"
                                : priceData === "1"
                                ? langPropsData === "EN"
                                  ? "Paid"
                                  : "Ücretli"
                                : null}
                            </div>
                          ))
                        ) : (
                          modalData.pricing.map((priceData, index) => (
                            <div className="price-ai" key={index}>
                              {priceData === "0"
                                ? langPropsData === "EN"
                                  ? "Free &"
                                  : "Ücretsiz &"
                                : priceData === "1"
                                ? langPropsData === "EN"
                                  ? "Paid"
                                  : "Ücretli"
                                : null}
                            </div>
                          ))
                        )
                      ) : (
                        <div>modalData.pricing is undefined or null</div>
                      )}
                    </div>
                  </div>
                  <div className="modal-body-both">
                    <div className="modal-body-left-title">Amacı</div>
                    <div>:</div>
                    <div className="modal-body-left-value">
                      {modalData.works_on}
                    </div>
                  </div>
                  <div style={{ border: "none" }} className="modal-body-both">
                    <div className="modal-body-left-title">Türleri</div>
                    <div>:</div>
                    <div className="modal-body-left-value">
                      {modalData && modalData.typeIcon ? (
                        modalData.typeIcon.map((aiTypeIcon, index) => {
                          const iconIndex = parseInt(aiTypeIcon) - 1;
                          return (
                            <Tooltip
                              key={index}
                              title={
                                aiTypeIcon === "1"
                                  ? langPropsData === "EN"
                                    ? "Audio"
                                    : "Ses"
                                  : aiTypeIcon === "2"
                                  ? langPropsData === "EN"
                                    ? "Code"
                                    : "Kod"
                                  : aiTypeIcon === "3"
                                  ? langPropsData === "EN"
                                    ? "Copywriting"
                                    : "Metin Yazarlığı"
                                  : aiTypeIcon === "4"
                                  ? langPropsData === "EN"
                                    ? "Education"
                                    : "Eğitim"
                                  : aiTypeIcon === "5"
                                  ? langPropsData === "EN"
                                    ? "Image"
                                    : "Resim"
                                  : aiTypeIcon === "6"
                                  ? langPropsData === "EN"
                                    ? "Research"
                                    : "Araştırma"
                                  : aiTypeIcon === "7"
                                  ? langPropsData === "EN"
                                    ? "Social Media"
                                    : "Sosyal Medya"
                                  : aiTypeIcon === "8"
                                  ? langPropsData === "EN"
                                    ? "Text"
                                    : "Metin"
                                  : aiTypeIcon === "9"
                                  ? langPropsData === "EN"
                                    ? "Translation"
                                    : "Çeviri"
                                  : aiTypeIcon === "10"
                                  ? langPropsData === "EN"
                                    ? "Video"
                                    : "Video"
                                  : aiTypeIcon === "11"
                                  ? langPropsData === "EN"
                                    ? "Generate AI"
                                    : "AI Üretmek"
                                  : null
                              }
                            >
                              <img
                                className="icons-in-model-type"
                                src={aiTypeIcons[iconIndex]}
                                alt="Types of AI, Types of Artifical Intelligence, AI usage areas, Yapay Zeka Türleri, AI Türleri, Yapay Zeka kullanım alanları"
                              />
                            </Tooltip>
                          );
                        })
                      ) : (
                        <p>No icons available</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
}

export default AICategoryView;
