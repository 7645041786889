import "./css/Footer.css";

function Footer({ langPropsData }) {
  return (
    <div className="footer-cover">
      {langPropsData === "EN"
        ? "Copyright © 2024 All Rights Reserved by Kadim Yazılım."
        : "Copyright © 2024 Tüm Hakları Kadim Yazılım'a Aittir."}
    </div>
  );
}

export default Footer;
