import { useEffect, useState } from "react";
import Lang from "../assets/svg/lang.svg";
import Menu from "../assets/svg/menu.svg";
import CloseMenu from "../assets/svg/closeMenu.svg";
import WebKY from "../assets/svg/webSiteKY.svg";
import PinOn from "../assets/svg/pinOn.svg";
import PinOff from "../assets/svg/pinOff.svg";
import Home from "../assets/svg/home.svg";
import Logo from "../assets/svg/tofindaiLogo.svg";
import Tooltip from "@mui/material/Tooltip";
import "./css/Navbar.css";

const Navbar = ({ setLangProps, setNumberProps }) => {
  const [openLang, setOpenLang] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [pinMenu, setPinMenu] = useState(true);
  const [language, setLanguage] = useState(() => {
    // localStorage'dan dil seçimini al
    return localStorage.getItem("lang") || "EN";
    // return sessionStorage.getItem("lang") || "EN";
  });

  useEffect(() => {
    // localStorage'a dil seçimini kaydet
    // sessionStorage.setItem("lang", language);
    localStorage.setItem("lang", language);
    if (language != 'EN') {
      setLangProps(language)
    }
  }, [language]);

  useEffect(() => {
    const timer = setTimeout(closeLanguageSelector, 6000);
    return () => clearTimeout(timer);
  }, [language]);

  const closeLanguageSelector = () => {
    setOpenLang(false);
  };

  const toggleLanguage = (newLanguage) => {
    try {
      setLanguage(newLanguage);
      setLangProps(newLanguage);
      setOpenLang((prevOpenLang) => !prevOpenLang);
    } catch (error) {
      console.error("Error in toggleLanguage:", error);
    }
  };

  const toggleContentNumber = (newContentNumber) => {
    try {
      setNumberProps(newContentNumber);
      setOpenMenu(false);
    } catch (error) {
      console.error("Error in toggleContentNumber:", error);
    }
  };

  const toggleMenu = () => {
    try {
      setOpenMenu(!openMenu);
      // debugger;
      if (!openMenu) {
        if (!pinMenu) {
          setPinMenu(true);
          setOpenMenu(false);
        } else {
          // setPinMenu(false)
        }
      } else {
        if (!pinMenu) {
          setPinMenu(true);
        }
      }

      // !openMenu ? setPinMenu(true) : setPinMenu(false)
    } catch (error) {
      console.error("Error in toggleContentNumber:", error);
    }
  };

  return (
    <>
      <div className="navbar-cover">
        <img
          className="navbar-menu-svg"
          src={!pinMenu ? CloseMenu : openMenu ? CloseMenu : Menu}
          onClick={toggleMenu}
          alt="About ai, what is ai, about artificle intelligence, yapay zeka nedir, yapay zeka, yapay zeka hakkında"
        />
        <a href="/" className="navbar-aiLogo-div">
          <img
            src={Logo}
            className="navbar-aiLogo-icon"
            alt="About ai, what is ai, about artificle intelligence, yapay zeka nedir, yapay zeka, yapay zeka hakkında"
          />
        </a>
        <div
          className={
            !pinMenu
              ? "menu-div-close"
              : !openMenu
              ? "menu-div-open"
              : "menu-div-close"
          }
        >
          <div className="menu-div-sub">
            <div
              onClick={() => {
                toggleContentNumber("0");
              }}
              className="navbar-menu-content-home"
            >
              <Tooltip
                title={language === "EN" ? "Home" : "Anasayfa"}
                placement="bottom"
              >
                <img
                  className="navbar-home-svg"
                  style={{ width: "90%" }}
                  src={Home}
                  alt="About ai, what is ai, about artificle intelligence, yapay zeka nedir, yapay zeka, yapay zeka hakkında"
                />
              </Tooltip>
            </div>
            <div
              onClick={() => {
                toggleContentNumber("1");
              }}
              className="navbar-menu-content"
            >
              {language === "EN" ? "What is AI?" : "Yapay Zeka Nedir?"}
            </div>
            <div
              onClick={() => {
                toggleContentNumber("2");
              }}
              className="navbar-menu-content"
            >
              {language === "EN" ? "Blog" : "Blog"}
            </div>
            <div className="navbar-menu-content-mid"></div>
            <div
              onClick={() => {
                toggleContentNumber("3");
              }}
              className="navbar-menu-content"
            >
              {language === "EN" ? "Contact" : "İletişim"}
            </div>
            <div
              onClick={() => {
                toggleContentNumber("4");
              }}
              className="navbar-menu-content"
            >
              {language === "EN" ? "F.A.Q" : "S.S.S"}
            </div>
            <div
              onClick={() => {
                setPinMenu(!pinMenu);
              }}
              className="navbar-menu-content-web"
            >
              <Tooltip
                title={language === "EN" ? "Pin it" : "Sabitle"}
                placement="bottom"
              >
                {/* <a target="blank" href="https://www.kadimyazilim.com"> */}
                <img
                  className="navbar-web-svg"
                  src={pinMenu ? PinOn : PinOff}
                  alt="toFindAI, tofindai, findai, find ai, to find ai, yapay zeka ara, yapay zeka"
                />
                {/* </a> */}
              </Tooltip>
            </div>
          </div>
        </div>
        <Tooltip
          title={language === "EN" ? "Site Language" : "Site Dili"}
          placement="left"
        >
          <div
            onClick={(event) => {
              event.preventDefault();
              setOpenLang(!openLang);
            }}
            className="flex-class"
          >
            <img
              className="navbar-lang-svg"
              src={Lang}
              alt="toFindAI, tofindai, findai, find ai, to find ai, yapay zeka ara, yapay zeka"
            />
            <div style={{ color: "white" }}>
              {language === "EN" ? "EN" : "TR"}
            </div>
          </div>
        </Tooltip>
      </div>
      <div className={!openLang ? "lang-div-open" : "lang-div-close"}>
        <button
          onClick={() => toggleLanguage("EN")}
          className={
            language === "EN" ? "lang-button-en active-lang" : "lang-button-en"
          }
        >
          EN
        </button>
        <button
          onClick={() => toggleLanguage("TR")}
          className={
            language === "TR" ? "lang-button-tr active-lang" : "lang-button-tr"
          }
        >
          TR
        </button>
      </div>
    </>
  );
};

export default Navbar;
