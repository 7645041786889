import Footer from "./Footer";
import "./css/Whatisai.css";

// Title Infos
const title1Tr = "Yapay Zeka Nedir?";
const title1En = "What is Artificial Intelligence?";
const title2Tr = "Yapay zekanın temel kavramları";
const title2En = "The fundamental concepts of artificial";
const title3Tr = "Yapay zeka günümüzde kullanım alanları";
const title3En = "Artificial intelligence is used in various fields today";
const title4Tr = "Yapay zekanın faydaları ve riskleri";
const title4En = "Benefits and risks of artificial intelligence";
const title5Tr = "Bununla birlikte, yapay zeka ile ilişkili riskler de vardır;";
const title5En = "However, there are also risks associated with AI;";
// Texts
const text1Tr = `Yapay Zeka (YZ), makinelerin insan benzeri bir zeka sergilemesini
                 sağlamak için geliştirilen bir bilim dalıdır. YZ'nin amacı,
                 makinelerin öğrenme, problem çözme, karar verme ve muhakeme gibi
                 bilişsel görevleri yerine getirmesini sağlamaktır. Yapay zekanın
                 tarihi 1950'lere kadar uzanmaktadır. İlk yapay zeka araştırmaları
                 satranç gibi oyunları oynayan makinelere odaklanmıştır. 1960'larda
                 araştırmalar dil işleme ve doğal dil anlayışına yöneldi. 1970'lerde
                 bilgisayar bilimi ve mühendisliğindeki gelişmeler yapay zeka
                 araştırmalarını hızlandırdı. 1980'lerde yapay zeka, robotik ve görüntü
                 tanıma alanlarında önemli ilerlemeler kaydetti. 1990'larda yapay zeka,
                 internet ve büyük veri gibi teknolojilerle yeni bir atılım yaşadı.
                 2000'li yıllarda ise bulut bilişim ve makine öğrenimi ile daha da
                 yaygınlaştı.`;
const text1En = `intelligence include learning, problem-solving, decision-making, and reasoning.
                Artificial intelligence aims to enable machines to learn from experience, acquire new knowledge, 
                improve their abilities, and change their behaviour. Machines can use various techniques for 
                problem-solving and make correct decisions to solve complex problems. 
                Machines can use different algorithms and techniques to make decisions.
                Artificial intelligence aims to enable machines to think logically by using various rules and techniques for reasoning.`;
const text2Tr = `Zeka; öğrenme, problem çözme, karar verme ve akıl yürütmeyi içerir.
                Yapay zeka, makinelerin deneyimlerinden öğrenmelerini, yeni bilgiler
                edinmelerini, yeteneklerini geliştirmelerini ve davranışlarını
                değiştirmelerini sağlamayı amaçlamaktadır. Makineler problem çözme
                için çeşitli teknikler kullanabilir ve karmaşık problemleri çözmek
                için doğru kararlar verebilir. Makineler karar vermek için farklı
                algoritmalar ve teknikler kullanabilir. Yapay zeka, akıl yürütme için
                çeşitli kurallar ve teknikler kullanarak makinelerin mantıklı
                düşünmesini sağlamayı amaçlamaktadır.`;
const text2En = `One of its applications is game playing, where it is used to develop
                computer programmes that can play games like chess, go, and poker.
                Another area of use is language processing. Artificial intelligence is
                used to develop language processing applications, such as natural
                language understanding and machine translation. It is also used to
                develop image recognition applications, including face recognition,
                object recognition, and image classification. Additionally, AI is used
                in robotics. Artificial intelligence enables robots to perceive and
                move around their environment. In medicine, AI is used for diagnosis,
                treatment, and patient monitoring. AI is also used in education to
                develop personalized learning and artificial assistants.The future of
                artificial intelligence is rapidly developing and widespread.`;
const text3Tr = `Uygulamalarından biri satranç, go ve poker gibi oyunları oynayabilen
                bilgisayar programları geliştirmek için kullanıldığı oyun oynamaktır.
                Bir başka kullanım alanı da dil işlemedir. Yapay zeka, doğal dil
                anlama ve makine çevirisi gibi dil işleme uygulamaları geliştirmek
                için kullanılır. Ayrıca yüz tanıma, nesne tanıma ve görüntü
                sınıflandırma gibi görüntü tanıma uygulamalarını geliştirmek için de
                kullanılır. Ayrıca, yapay zeka robotikte de kullanılmaktadır. Yapay
                zeka, robotların çevrelerini algılamalarını ve hareket etmelerini
                sağlar. Tıpta, YZ teşhis, tedavi ve hasta izleme için kullanılır.
                Yapay zeka Eğitimde de kişiselleştirilmiş öğrenme ve yapay asistanlar
                geliştirmek için kullanılıyor. Yapay zeka hızla gelişiyor ve
                yaygınlaşıyor. Birçok alanda da devrim yaratması bekleniyor.`;
const text3En = `One of its applications is that it can play games such as chess, go
                and poker. It is a game that is used to develop computer programs.
                Another area of use is language processing. Artificial intelligence,
                natural language developing language processing applications such as
                comprehension and machine translation using for. Also face
                recognition, object recognition and image to improve image recognition
                applications such as classification is used. Additionally, artificial
                intelligence is also used in robotics. Artificial intelligence enables
                robots to perceive their environment and act provides. In medicine, AI
                is used for diagnosis, treatment and patient monitoring. Artificial
                intelligence Personalized learning and artificial assistants in
                education is used for development. Artificial intelligence is
                developing rapidly and is becoming widespread. It is expected to
                revolutionize many areas.`;
const text4Tr = `Yapay zeka, makinelerin görevleri otomatik olarak yerine getirmesini
                sağlayarak üretkenliği artırabilir. Ayrıca, yeni ürün ve hizmetler
                geliştirmenin yanı sıra yeni ürünler ve hizmetler sunmak için de
                kullanılabilir. Kullanıcılara kişiselleştirilmiş deneyimler sunabilir.`;
const text4En = `Artificial intelligence allows machines to perform tasks automatically
                can increase productivity by providing Additionally, new products and services
                to develop as well as to offer new products and services.
                available. It can offer personalized experiences to users.              `;
const text5Tr = `YZ, bazı işleri otomatikleştirerek işsizliği artırabilir ve bu da
                eşitsizliklerin artmasına neden olabilir. Gelir ve fırsat eşitsizliği
                yaratmaktadır. Ayrıca, yapay zeka kullanımı etik sorunları da gündeme
                getirmektedir. Yapay zeka, hızla gelişen ve giderek yaygınlaşan bir
                teknolojidir. Yapay zekayı, hem faydalarını hem de risklerini göz
                önünde bulundurarak sorumlu bir şekilde kullanmak çok önemlidir.`;
const text5En = `AI could increase unemployment by automating some jobs, which could
                may lead to increased inequalities. Income and opportunity inequality
                It creates. Additionally, the use of artificial intelligence raises
                ethical issues. It brings. Artificial intelligence is a rapidly
                developing and increasingly widespread is technology. Consider
                artificial intelligence, both its benefits and risks It is very
                important to use it responsibly.`;

function WhatIsAI({ langPropsData }) {
  return (
    <>
      <div className="whatisai-cover">
        <div className="whatisai-content">
          <div className="whatisai-title">
            {langPropsData === "EN" ? title1En : title1Tr}
          </div>
          <div className="whatisai-text">
            {langPropsData === "EN" ? text1En : text1Tr}
          </div>
          <br />
          <div className="whatisai-title">
            {langPropsData === "EN" ? title2En : title2Tr}
          </div>
          <div className="whatisai-text">
            {langPropsData === "EN" ? text2En : text2Tr}
          </div>
          <br />
          <div className="whatisai-title">
            {langPropsData === "EN" ? title3En : title3Tr}
          </div>
          <div className="whatisai-text">
            {langPropsData === "EN" ? text3En : text3Tr}
          </div>
          <br />
          <div className="whatisai-title">
            {langPropsData === "EN" ? title4En : title4Tr}
          </div>
          <div className="whatisai-text">
            {langPropsData === "EN" ? text4En : text4Tr}
          </div>
          <br />
          <div className="whatisai-title">
            {langPropsData === "EN" ? title5En : title5Tr}
          </div>
          <div className="whatisai-text">
            {langPropsData === "EN" ? text5En : text5Tr}
          </div>
        </div>
      </div>
      <Footer langPropsData={langPropsData} />
    </>
  );
}

export default WhatIsAI;
