export const VideoTr = [
  {
    id: 10001,
    name: "Leonardo AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Leonardo AI Inc.",
    webUrl: "https://leonardo.ai/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar ve diğer görsel içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
  },
  {
    id: 10002,
    name: "Adobe Firefly",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Adobe",
    webUrl: "https://firefly.adobe.com/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar ve diğer görsel içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
  },
  {
    id: 10003,
    name: "Runway",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Runway AI",
    webUrl: "https://runwayml.com/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar, videolar ve diğer görsel içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
  },
  {
    id: 10004,
    name: "VEED.IO",
    founding_year: "2017",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "VEED.IO Pte. Ltd.",
    webUrl: "https://www.veed.io/",
    works_on:
      "Metin açıklamalarına göre videolar oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["10"],
  },
  {
    id: 10005,
    name: "Pictory",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Pictory AI Inc.",
    webUrl: "https://pictory.ai/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar ve videolar oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
  },
  {
    id: 1005,
    name: "Heygen AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "HEYGEN AI Inc.",
    webUrl: "https://www.heygen.com/",
    works_on:
      "Metin açıklamalarına göre videolar, animasyonlar ve ses dosyaları oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["1", "10"],
  },
  {
    id: 10007,
    name: "TinyWow",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "TinyWow Teknoloji",
    webUrl: "https://tinywow.com/",
    works_on:
      "Türkiye merkezli bir yapay zeka şirketi olan TinyWow Teknoloji tarafından geliştirilen bir web sitesidir. Site, kullanıcıların dosya dönüştürme, görsel düzenleme, video düzenleme ve yapay zeka tabanlı diğer görevleri gerçekleştirmesine olanak tanır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "8", "10"],
  },
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "Görüntülerdeki nesneleri ve olayları tanımak için, metinleri analiz etmek için ve sesleri tanımak için web sitesi veya API aracılığı ile kullanılabilir. ",
    pricing: ["0", "1"],
    typeIcon: ["1", "2", "5", "8", "10"],
  },
];

export const VideoEn = [
  {
    id: 10001,
    name: "Leonardo AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Leonardo AI Inc.",
    webUrl: "https://leonardo.ai/",
    works_on:
      "It is an artificial intelligence tool that allows it to create images, drawings, animations, and other visual content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords: "Leonardo AI, draw, create animations, create images",
  },
  {
    id: 10002,
    name: "Adobe Firefly",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Adobe",
    webUrl: "https://firefly.adobe.com/",
    works_on:
      "It is an artificial intelligence tool that allows it to create images, drawings, animations, and other visual content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords:
      "Adobe Firefly, creating images, drawing, creating visual content",
  },
  {
    id: 10003,
    name: "Runway",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Runway AI",
    webUrl: "https://runwayml.com/",
    works_on:
      "It is an artificial intelligence tool that allows it to create images, drawings, animations, videos and other visual content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords:
      "Runway, create images, draw, create videos, create visual content",
  },
  {
    id: 10004,
    name: "VEED.IO",
    founding_year: "2017",
    type: "Natural Language Processing, Image Processing",
    developer: "VEED.IO Pte. Ltd.",
    webUrl: "https://www.veed.io/",
    works_on:
      "It is an artificial intelligence tool that allows creating videos based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["10"],
    keywords: "",
  },
  {
    id: 10005,
    name: "Pictory",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Pictory AI Inc.",
    webUrl: "https://pictory.ai/",
    works_on:
      "It is an artificial intelligence tool that allows creating images, drawings, animations and videos based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords: "Pictory, create image, draw, create drawing, create video",
  },
  {
    id: 1005,
    name: "Heygen AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "HEYGEN AI Inc.",
    webUrl: "https://www.heygen.com/",
    works_on:
      "It is an artificial intelligence tool that allows creating videos, animations and audio files based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["1", "10"],
    keywords: "Heygen AI, create animation, create video, create audio",
  },
  {
    id: 10007,
    name: "TinyWow",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "TinyWow Teknoloji",
    webUrl: "https://tinywow.com/",
    works_on:
      "It is a website developed by TinyWow Technology, an artificial intelligence company based in Turkey. The site allows users to perform file conversion, image editing, video editing, and other AI-based tasks.",
    pricing: ["0", "1"],
    typeIcon: ["5", "8", "10"],
    keywords:
      "Tinywow, file conversion, image editing, photo editing, video editing",
  },
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "It can be used via the website or API to recognize objects and events in images, analyze texts, and recognize sounds.",
    pricing: ["0", "1"],
    typeIcon: ["1", "2", "5", "8", "10"],
    keywords:
      "Clarif AI, image identification, photo identification, object identification, text analysis, voice recognition, video identification",
  },
];
