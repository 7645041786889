export const allAiTr = [
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "Görüntülerdeki nesneleri ve olayları tanımak için, metinleri analiz etmek için ve sesleri tanımak için web sitesi veya API aracılığı ile kullanılabilir. ",
    pricing: ["0", "1"],
    typeIcon: ["1", "2", "5", "8", "10"],
    keywords:
      "Clarif AI, görüntü tanımlama, fotoğraf tanımlama, nesne tanımlama, metin analizi, ses tanıma, video tanımlama",
  },
  {
    id: 1002,
    name: "Descript",
    founding_year: "2018",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Descript",
    webUrl: "https://www.descript.com/",
    works_on:
      "Görüntülerdeki nesneleri ve olayları tanımak için, metinleri analiz etmek için ve sesleri tanımak için web sitesi veya API aracılığı ile kullanılabilir. Ses ve videoları metin haline getirebilir. ",
    pricing: ["0", "1"],
    typeIcon: ["1", "8"],
    keywords:
      "Descript, görüntü tanımlama, fotoğraf tanımlama, nesne tanımlama, metin analizi, ses tanıma, video tanımlama, sesten metin haline dönüştürme",
  },
  {
    id: 1003,
    name: "Sonix AI",
    founding_year: "2017",
    type: "Doğal Dil İşleme",
    developer: "Sonix Inc.",
    webUrl: "https://sonix.ai/",
    works_on:
      "Ses ve video dosyalarını metne dönüştürmek için kullanılan bir araçtır.",
    pricing: ["0", "1"],
    typeIcon: ["1"],
    keywords:
      "Sonix AI, sesten metine dönüştürme, sesten metne dönüştürme, videodan metin oluşturma",
  },
  {
    id: 1005,
    name: "Heygen AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "HEYGEN AI Inc.",
    webUrl: "https://www.heygen.com/",
    works_on:
      "Metin açıklamalarına göre videolar, animasyonlar ve ses dosyaları oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["1", "10"],
    keywords: "Heygen AI, animasyon oluşturma, video oluşturma, ses oluşturma",
  },
  {
    id: 11001,
    name: "Droxy AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Droxy AI Inc.",
    webUrl: "https://www.droxy.ai/",
    works_on:
      "Kullanıcıların kod yazmadan kendi chatbotlarını oluşturmalarına olanak tanıyan bir yapay zeka chatbot oluşturucusudur.",
    pricing: ["0", "1"],
    typeIcon: ["11"],
    keywords:
      "Droxy AI, yapay zeka oluşturma, chatbot, kodsuz yapay zeka, kodlamasız yapay zeka",
  },
  {
    id: 2002,
    name: "Blacbox ",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Blackbox AI",
    webUrl: "https://www.blackbox.global/",
    works_on:
      " Kullanıcıların metinleri ve kodları analiz etmelerine yardımcı olan bir yapay zeka platformudur. ",
    pricing: ["0", "1"],
    typeIcon: ["2"],
    keywords:
      "Blackbox, kodlama, kod yardımcısı, kodlama yapay zeka, visual studio code",
  },
  {
    id: 2003,
    name: "Github Copilot",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Github ve Open AI",
    webUrl: "https://github.com/features/copilot",
    works_on:
      "Kod tamamlama aracıdır. Visual Studio Code, Visual Studio, Neovim ve diğer entegre geliştirme ortamlarının (IDE'ler) kullanıcılarına destek vermek için tasarlanmıştır.",
    pricing: ["0", "1"],
    typeIcon: ["2"],
    keywords:
      "Github Copilot, kodlama, kod yardımcısı, kodlama yapay zeka, visual studio code",
  },
  {
    id: 3001,
    name: "ChatGPT",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "OpenAI",
    webUrl: "https://chat.openai.com/",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, özetleme yapabilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Chatgpt, metin oluşturma, içerik üretme, çeviri, soru cevaplama, doğal dil işleme",
  },
  {
    id: 3002,
    name: "Google Bard",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Google",
    webUrl: "https://bard.google.com/",
    works_on:
      "Bilgi verir, metinleri anlar ve cevaplar üretir, içerik üretir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
    keywords:
      "Google Bard, metin oluşturma, soru cevaplama, içerik üretme, doğal dil işleme",
  },
  {
    id: 3004,
    name: "Claude AI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Anthropic",
    webUrl: "https://claude.ai/",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, özetleme yapabilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Claude AI, metin oluşturma, içerik üretme çeviri, soru cevaplama, metin özetleme, doğal dil işleme",
  },
  {
    id: 3006,
    name: "Jasper",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Google",
    webUrl: "https://www.jasper.ai/",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, verileri analiz edebilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
    keywords:
      "Jasper, metin oluşturma, çeviri, içerik üretme, veri analizi, soru cevaplama, doğal dil işleme",
  },
  {
    id: 3008,
    name: "Copy.ai",
    founding_year: "2020",
    type: "Doğal Dil İşleme",
    developer: "Copy AI Inc.",
    webUrl: "https://www.copy.ai/",
    works_on:
      "Metin açıklamalarına göre çeşitli yaratıcı içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords: "copy.ai, içerik üretme, doğal dil işleme",
  },
  {
    id: 4001,
    name: "CopyLeaks",
    founding_year: "2015",
    type: "Doğal Dil İşleme",
    developer: "CopyLeaks",
    webUrl: "https://copyleaks.com/",
    works_on:
      "Metin içinde benzersiz olmayan içeriği tespit etmek için kullanılır.",
    pricing: ["0", "1"],
    typeIcon: ["3", "4", "8"],
    keywords: "Copyleaks, intihal, benzerlik tespiti",
  },
  {
    id: 4002,
    name: "Scisummary",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Hunts AI",
    webUrl: "https://scisummary.com/",
    works_on:
      "Akademik makaleleri özetlemek için kullanılan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
    keywords: "scisummary, makale özeti, makale özetleme, metin özetleme",
  },
  {
    id: 4003,
    name: "Grammarly",
    founding_year: "2009",
    type: "Doğal Dil İşleme",
    developer: "Blackstone",
    webUrl: "https://www.grammarly.com/",
    works_on:
      "Grammarly, dilbilgisi, yazım ve stil hatalarını tespit eden ve düzeltmeler öneren bir yapay zeka uygulamasıdır.Kullanıcıların e-postalarına cevap verme, hazırdaki bir içeriği anlaşılırlığa uygun bir şekilde yeniden yazma, içerikleri belirlenen kelime sayısına uygun bir şekilde uzatma gibi işlemleri de yapabilir.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
    keywords:
      "grammarly, yazım hatası, e-posta cevaplama, yeniden yazma, dilbilgisi, tutarsızlık tespit etme",
  },
  {
    id: 4004,
    name: "Beautiful AI",
    founding_year: "2019",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Beautiful.ai Inc.",
    webUrl: "https://www.beautiful.ai/",
    works_on:
      "Profesyonel görünümlü sunumlar oluşturmak için kullanılan bir yapay zeka araçları paketidir.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
    keywords: "Beautiful AI, sunum oluşturma",
  },
  {
    id: 5001,
    name: "Midjourney",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Midjourney GmbH",
    webUrl: "https://www.midjourney.com",
    works_on:
      "Metin açıklamalarına göre resimler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
    keywords: "Midjourney, resim oluşturma, görüntü oluşturma",
  },
  {
    id: 5002,
    name: "DALL-E 2",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "OpenAI",
    webUrl: "https://openai.com/dall-e-2",
    works_on:
      "Metin açıklamalarına göre resimler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
    keywords: "dall-e 2, resim oluşturma, görüntü oluşturma",
  },
  {
    id: 5003,
    name: "DALL-E 3",
    founding_year: "2023",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "OpenAI",
    webUrl: "https://openai.com/dall-e-3",
    works_on:
      "Metin açıklamalarına göre resimler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
    keywords: "Dall-e 3, resim oluşturma, görüntü oluşturma",
  },
  {
    id: 5010,
    name: "logoai",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "LOGO AI",
    webUrl: "https://www.logoai.com/",
    works_on:
      "Kullanıcıların kendi logolarını oluşturmalarına yardımcı olan bir yapay zeka aracıdır.",
    pricing: ["1"],
    typeIcon: ["5", "10"],
    keywords: "logoai, logo oluşturma, logo yapma",
  },
  {
    id: 6001,
    name: "HARPA AI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Harpa AI Inc.",
    webUrl: "https://harpa.ai/",
    works_on:
      "Kullanıcılar, doğal dil işleme, veri analizi ve öneri sistemleri gibi yapay zeka teknolojilerini kullanarak bilgiye erişebilir, sorular sorabilir ve öneriler alabilir.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8", "6"],
    keywords:
      "Harpa AI, araştırma yapma, araştırma yapay zeka, veri analizi, web otomasyon",
  },
  {
    id: 7001,
    name: "Clearscope",
    founding_year: "2017",
    type: "Doğal Dil İşleme",
    developer: "Clearscope AI",
    webUrl: "https://www.clearscope.io/",
    works_on:
      "Web sitelerinin arama motoru optimizasyonu (SEO) performansını iyileştirmeye yardımcı olan bir yapay zeka aracıdır.",
    pricing: ["1"],
    typeIcon: ["7"],
    keywords:
      "Clearscope, seo, arama motoro optimizasyonu, web site, web site performansı",
  },
  {
    id: 7002,
    name: "BlogSEO AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "BlogSEO AI",
    webUrl: "https://www.blogseo.ai/",
    works_on:
      "Arama motoru optimizasyonunu (SEO) iyileştirmeye yardımcı olan bir yapay zeka aracıdır.",
    pricing: ["1"],
    typeIcon: ["7"],
    keywords:
      "blogseo AI, seo, arama motoro optimizasyonu, web site, web site performansı",
  },
  {
    id: 7003,
    name: "vidIQ",
    founding_year: "2014",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "vidIQ Inc.",
    webUrl: "https://vidiq.com/",
    works_on:
      "YouTube içerik oluşturucuları için bir yapay zeka araçları ve analiz paketidir ancak bazı özellikleri web siteler için de kullanılabilir. Performansınızı izlemenize, analiz etmenize ve geliştirmenize yardımcı olur.",
    pricing: ["0", "1"],
    typeIcon: ["7"],
    keywords:
      "vidiq, youtube, analiz, youtube analiz, web site analiz, web site performans, web site performans analizi",
  },
  {
    id: 7004,
    name: "Seona AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Seona AI Inc.",
    webUrl: "https://seona.usestyle.ai/",
    works_on:
      "Youtube kanalınızın ve web sitenizin performansını izlemenize, analiz etmenize ve geliştirmenize yardımcı olur. ",
    pricing: ["0", "1"],
    typeIcon: ["7"],
    keywords:
      "Seona AI, youtube kanal performansı izleme, youtube kanal performans analizi, web site performans izleme, web site performans analizi",
  },
  {
    id: 8003,
    name: "Bing AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Microsoft",
    webUrl: "https://www.bing.com/",
    works_on:
      "Metin oluşturabilir, dile çevirisi yapabilir, planlama yapabilir, öneriler verebilir, soru cevaplayabilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
    keywords:
      "Bing AI, metin oluşturma, soru cevaplama, çeviri yapma, planlama, öneri, doğal dil işleme",
  },
  {
    id: 8005,
    name: "Notion AI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Notion",
    webUrl: "https://www.notion.so/product/ai",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, verileri analiz edebilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Notion AI, metin oluşturma, içerik üretme, soru cevaplama, veri analizi, çeviri, doğal dil işleme",
  },
  {
    id: 8007,
    name: "OctiAI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Google",
    webUrl: "https://www.octiai.com/",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, verileri analiz edebilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
    keywords:
      "OctiAI, metin oluşturma, içerik üretme, soru cevaplama, çeviri, doğal dil işleme",
  },
  {
    id: 8009,
    name: "HyperWrite",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "HyperWrite AI Inc.",
    webUrl: "https://www.hyperwriteai.com/",
    works_on:
      "Metin açıklamalarına göre çeşitli yaratıcı içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords: "Hyperwrite, içerik üretme, doğal dil işleme",
  },
  {
    id: 8010,
    name: "ChatPDF",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "ChatPDF AI Inc.",
    webUrl: "https://www.chatpdf.com/",
    works_on:
      "Kullanıcıların PDF dosyalarını sohbet ederek anlamalarına ve sorgulamalarına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["8"],
    keywords: "Chatpdf, pdf okuma, soru cevap",
  },
  {
    id: 8011,
    name: "writesonic",
    founding_year: "2020",
    type: "Doğal Dil İşleme",
    developer: "Writesonic AI",
    webUrl: "https://writesonic.com/",
    works_on:
      "İçerik oluşturmaya yardımcı olan bir yapay zeka aracıdır. Makaleler, blog gönderileri, sosyal medya gönderileri, e-posta, reklamlar ve daha fazlası gibi çeşitli içerik türleri oluşturabilir.",
    pricing: ["1"],
    typeIcon: ["3", "7", "8"],
    keywords:
      "writesonic, içerik üretme, metin oluşturma, makale oluşturma, sosyal medya gönderisi, e-posta oluşturma, reklam oluşturma, blog gönderisi oluşturma",
  },
  {
    id: 8013,
    name: "Perplexity",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Perplexity AI",
    webUrl: "https://www.perplexity.ai/",
    works_on:
      "Kullanıcıların sohbet yoluyla soru sormasına ve yanıt almasına olanak tanıyan bir yapay zeka sohbet robotudur.",
    pricing: ["0", "1"],
    typeIcon: ["8"],
    keywords: "Perplexity, soru cevaplama",
  },
  {
    id: 8014,
    name: "Rytr",
    founding_year: "2021",
    type: "Doğal Dil İşleme",
    developer: "Rytr AI",
    webUrl: "https://rytr.me/",
    works_on:
      "İçerik oluşturmaya yardımcı olan bir yapay zeka aracıdır. Makaleler, blog gönderileri, sosyal medya gönderileri, e-posta, reklamlar ve daha fazlası gibi çeşitli içerik türleri oluşturabilir.",
    pricing: ["1"],
    typeIcon: ["8"],
    keywords:
      "Rytr, içerik üretme, metin oluşturma, makale oluşturma, sosyal medya içerik oluşturma, blog oluşturma, e-posta oluşturma, reklam oluşturma",
  },
  {
    id: 9001,
    name: "DeepL",
    founding_year: "2017",
    type: "Doğal Dil İşleme",
    developer: "Linguee",
    webUrl: "https://www.deepl.com",
    works_on:
      "Metinleri bir dilden diğerine çeviren ve çevrilen dilin yapısına göre iyileştirmeler yapabilen bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["8", "9"],
    keywords: "DeepL, çeviri yapma, metinde düzenlemeler yapma",
  },
  {
    id: 10001,
    name: "Leonardo AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Leonardo AI Inc.",
    webUrl: "https://leonardo.ai/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar ve diğer görsel içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords:
      "Leonardo AI, resim oluşturma, görüntü oluşturma, çizim yapma, animasyon oluşturma, görsel oluşturma",
  },
  {
    id: 10002,
    name: "Adobe Firefly",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Adobe",
    webUrl: "https://firefly.adobe.com/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar ve diğer görsel içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords:
      "Adobe Firefly, resim oluşturma, görüntü oluşturma, çizim yapma, görsel içerik oluşturma",
  },
  {
    id: 10003,
    name: "Runway",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Runway AI",
    webUrl: "https://runwayml.com/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar, videolar ve diğer görsel içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords:
      "Runway, resim oluşturma, görüntü oluşturma, çizim yapma, video oluşturma, görsel içerik oluşturma",
  },
  {
    id: 10004,
    name: "VEED.IO",
    founding_year: "2017",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "VEED.IO Pte. Ltd.",
    webUrl: "https://www.veed.io/",
    works_on:
      "Metin açıklamalarına göre videolar oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["10"],
    keywords: "veed.io, video oluşturma",
  },
  {
    id: 10005,
    name: "Pictory",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Pictory AI Inc.",
    webUrl: "https://pictory.ai/",
    works_on:
      "Metin açıklamalarına göre resimler, çizimler, animasyonlar ve videolar oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords:
      "Pictory, resim oluşturma, görüntü oluşturma, çizim yapma, çizim oluşturma, video oluşturma",
  },
  {
    id: 10007,
    name: "TinyWow",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "TinyWow Teknoloji",
    webUrl: "https://tinywow.com/",
    works_on:
      "Türkiye merkezli bir yapay zeka şirketi olan TinyWow Teknoloji tarafından geliştirilen bir web sitesidir. Site, kullanıcıların dosya dönüştürme, görsel düzenleme, video düzenleme ve yapay zeka tabanlı diğer görevleri gerçekleştirmesine olanak tanır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "8", "10"],
    keywords:
      "Tinywow, dosya dönüştürme, görsel düzenleme, resim düzenleme, fotoğraf düzenleme, video düzenleme",
  },
];

export const allAiEn = [
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "It can be used via the website or API to recognize objects and events in images, analyze texts, and recognize sounds.",
    pricing: ["0", "1"],
    typeIcon: ["1", "2", "5", "8", "10"],
    keywords:
      "Clarif AI, image identification, photo identification, object identification, text analysis, voice recognition, video identification",
  },
  {
    id: 1002,
    name: "Descript",
    founding_year: "2018",
    type: "Natural Language Processing, Image Processing",
    developer: "Descript",
    webUrl: "https://www.descript.com/",
    works_on:
      "It can be used via the website or API to recognize objects and events in images, analyze texts, and recognize sounds. It can convert audio and videos into text.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8"],
    keywords:
      "Descript, image identification, photo identification, object identification, text analysis, voice recognition, video identification, audio to text conversion",
  },
  {
    id: 1003,
    name: "Sonix AI",
    founding_year: "2017",
    type: "Natural Language Processing",
    developer: "Sonix Inc.",
    webUrl: "https://sonix.ai/",
    works_on: "It is a tool used to convert audio and video files to text.",
    pricing: ["0", "1"],
    typeIcon: ["1"],
    keywords:
      "Sonix AI, convert audio to text, convert audio to text, create text from video",
  },
  {
    id: 1005,
    name: "Heygen AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "HEYGEN AI Inc.",
    webUrl: "https://www.heygen.com/",
    works_on:
      "It is an artificial intelligence tool that allows it to generate videos, animations, and audio files based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["1", "10"],
    keywords: "Heygen AI, create animation, create video, create audio",
  },
  {
    id: 11001,
    name: "Droxy AI",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Droxy AI Inc.",
    webUrl: "https://www.droxy.ai/",
    works_on:
      "It is an artificial intelligence chatbot creator that allows users to create their own chatbots without writing code.",
    pricing: ["0", "1"],
    typeIcon: ["11"],
    keywords: "Droxy AI, building artificial intelligence, chatbot, no-code AI",
  },
  {
    id: 2002,
    name: "Blacbox ",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Blackbox AI",
    webUrl: "https://www.blackbox.global/",
    works_on:
      "It is an artificial intelligence platform that helps users analyze texts and codes.",
    pricing: ["0", "1"],
    typeIcon: ["2"],
    keywords:
      "Blackbox, coding, code assistant, coding artificial intelligence, visual studio code",
  },
  {
    id: 2003,
    name: "Github Copilot",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Github ve Open AI",
    webUrl: "https://github.com/features/copilot",
    works_on:
      "It is a code completion tool. Visual Studio Code is designed to support users of Visual Studio, Neovim, and other integrated development environments (IDEs).",
    pricing: ["0", "1"],
    typeIcon: ["2"],
    keywords:
      " Github Copilot, coding, code assistant, coding artificial intelligence, visual studio code",
  },
  {
    id: 3001,
    name: "ChatGPT",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "OpenAI",
    webUrl: "https://chat.openai.com/",
    works_on:
      "It can compose text, translate languages, help answer questions, summarize, and perform many other language processing tasks.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Chatgpt, text creation, content production, translation, question answering, natural language processing",
  },
  {
    id: 3002,
    name: "Google Bard",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Google",
    webUrl: "https://bard.google.com/",
    works_on:
      "It provides information, understands texts and generates answers, generates content, and can perform many other language processing tasks.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
    keywords:
      "Googl Bard, text creation, question answering, content generation, natural language processing",
  },
  {
    id: 3004,
    name: "Claude AI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Anthropic",
    webUrl: "https://claude.ai/",
    works_on:
      "It can compose text, translate languages, help answer questions, summarize, and perform many other language processing tasks.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Claude AI, text generation, content generation, translation, question answering, text summarization, natural language processing",
  },
  {
    id: 3006,
    name: "Jasper",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Google",
    webUrl: "https://www.jasper.ai/",
    works_on:
      "It can compose text, translate languages, help answer questions, analyze data, and perform many more language processing tasks.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
    keywords:
      "Jasper, text creation, translation, content generation, data analysis, question answering, natural language processing",
  },
  {
    id: 3008,
    name: "Copy.ai",
    founding_year: "2020",
    type: "Natural Language Processing",
    developer: "Copy AI Inc.",
    webUrl: "https://www.copy.ai/",
    works_on:
      "It is an artificial intelligence tool that allows creating various creative content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords: "copy.ai, content generation, natural language processing",
  },
  {
    id: 4001,
    name: "CopyLeaks",
    founding_year: "2015",
    type: "Natural Language Processing",
    developer: "CopyLeaks",
    webUrl: "https://copyleaks.com/",
    works_on: "It is used to detect non-unique content within text.",
    pricing: ["0", "1"],
    typeIcon: ["3", "4", "8"],
    keywords: "Copyleaks, plagiarism, similarity detection",
  },
  {
    id: 4002,
    name: "Scisummary",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Hunts AI",
    webUrl: "https://scisummary.com/",
    works_on:
      "It is an artificial intelligence tool used to summarize academic articles.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
    keywords:
      "scisummary, article summary, article summarization, text summarization",
  },
  {
    id: 4003,
    name: "Grammarly",
    founding_year: "2009",
    type: "Natural Language Processing",
    developer: "Grammarly Inc.",
    webUrl: "https://www.grammarly.com/",
    works_on:
      "Grammarly is an artificial intelligence application that detects grammatical, spelling and style errors and suggests corrections. It can also perform operations such as responding to users' e-mails, rewriting existing content for clarity, and extending the content in accordance with the specified word count.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
    keywords:
      "grammarly, typo, email reply, rewrite, grammar, inconsistency detection",
  },
  {
    id: 4004,
    name: "Beautiful AI",
    founding_year: "2019",
    type: "Natural Language Processing, Image Processing",
    developer: "Beautiful.ai Inc.",
    webUrl: "https://www.beautiful.ai/",
    works_on:
      "It is a suite of artificial intelligence tools used to create professional-looking presentations.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
    keywords: "Beautiful AI, presentation creation",
  },
  {
    id: 5001,
    name: "Midjourney",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Midjourney GmbH",
    webUrl: "https://www.midjourney.com",
    works_on:
      "It is an artificial intelligence tool that allows creating images based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
    keywords: "Midjourney, image creation, rendering",
  },
  {
    id: 5002,
    name: "DALL-E 2",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "OpenAI",
    webUrl: "https://openai.com/dall-e-2",
    works_on:
      "It is an artificial intelligence tool that allows creating images based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
    keywords: "dall-e 2, rendering, rendering",
  },
  {
    id: 5003,
    name: "DALL-E 3",
    founding_year: "2023",
    type: "Natural Language Processing, Image Processing",
    developer: "OpenAI",
    webUrl: "https://openai.com/dall-e-3",
    works_on:
      "It is an artificial intelligence tool that allows creating images based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5"],
    keywords: "Dall-e 3, rendering, rendering",
  },
  {
    id: 5010,
    name: "logoai",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "LOGO AI",
    webUrl: "https://www.logoai.com/",
    works_on:
      "It is an artificial intelligence tool that helps users create their own logos.",
    pricing: ["1"],
    typeIcon: ["5", "10"],
    keywords: "logoai, logo creation, logo making",
  },
  {
    id: 6001,
    name: "HARPA AI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Harpa AI Inc.",
    webUrl: "https://harpa.ai/",
    works_on:
      "Users can access information, ask questions, and receive recommendations using artificial intelligence technologies such as natural language processing, data analysis, and recommendation systems.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8", "6"],
    keywords:
      "Harpa AI, doing research, research artificial intelligence, data analysis, web automation",
  },
  {
    id: 7001,
    name: "Clearscope",
    founding_year: "2017",
    type: "Natural Language Processing",
    developer: "Clearscope AI",
    webUrl: "https://www.clearscope.io/",
    works_on:
      "It is an artificial intelligence tool that helps improve the search engine optimization (SEO) performance of websites.",
    pricing: ["1"],
    typeIcon: ["7"],
    keywords:
      "Clearscope, seo, search engine optimization, website, website performance",
  },
  {
    id: 7002,
    name: "BlogSEO AI",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "BlogSEO AI",
    webUrl: "https://www.blogseo.ai/",
    works_on:
      "It is an artificial intelligence tool that helps improve search engine optimization (SEO).",
    pricing: ["1"],
    typeIcon: ["7"],
    keywords:
      "blogseo AI, seo, search engine optimization, website, website performance",
  },
  {
    id: 7003,
    name: "vidIQ",
    founding_year: "2014",
    type: "Natural Language Processing, Image Processing",
    developer: "vidIQ Inc.",
    webUrl: "https://vidiq.com/",
    works_on:
      "It is a suite of AI tools and analytics for YouTube creators, but some of its features can also be used for websites. It helps you track, analyze and improve your performance.",
    pricing: ["0", "1"],
    typeIcon: ["7"],
    keywords:
      "vidiq, youtube, analysis, youtube analysis, website analysis, website performance, website performance analysis",
  },
  {
    id: 7004,
    name: "Seona AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Seona AI Inc.",
    webUrl: "https://seona.usestyle.ai/",
    works_on:
      "It helps you monitor, analyze and improve the performance of your YouTube channel and website.",
    pricing: ["0", "1"],
    typeIcon: ["7"],
    keywords:
      "Seona AI, youtube channel performance monitoring, youtube channel performance analysis, website performance monitoring, website performance analysis",
  },
  {
    id: 8003,
    name: "Bing AI",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Microsoft",
    webUrl: "https://www.bing.com/",
    works_on:
      "It can create text, translate it into languages, plan, give suggestions, answer questions, and perform many other language processing tasks.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
    keywords:
      "Bing AI, text creation, question answering, content generation,translation, planning, suggestion, natural language processing",
  },
  {
    id: 8005,
    name: "Notion AI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Notion",
    webUrl: "https://www.notion.so/product/ai",
    works_on:
      "It can compose text, translate languages, help answer questions, analyze data, and perform many more language processing tasks.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Notion AI, text creation, content generation, question answering, data analysis, translation, natural language processing",
  },
  {
    id: 8007,
    name: "OctiAI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Google",
    webUrl: "https://www.octiai.com/",
    works_on:
      "It can compose text, translate languages, help answer questions, analyze data, and perform many more language processing tasks.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
    keywords:
      "OctiAI, text creation, content generation, question answering, translation, natural language processing",
  },
  {
    id: 8009,
    name: "HyperWrite",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "HyperWrite AI Inc.",
    webUrl: "https://www.hyperwriteai.com/",
    works_on:
      "It is an artificial intelligence tool that allows creating various creative content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords: "Hyperwrite, content generation, natural language processing",
  },
  {
    id: 8010,
    name: "ChatPDF",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "ChatPDF AI Inc.",
    webUrl: "https://www.chatpdf.com/",
    works_on:
      "It is an artificial intelligence tool that allows users to understand and query PDF files through chat.",
    pricing: ["0", "1"],
    typeIcon: ["8"],
    keywords: "Chatpdf, pdf reading, question and answer",
  },
  {
    id: 8011,
    name: "writesonic",
    founding_year: "2020",
    type: "Natural Language Processing",
    developer: "Writesonic AI",
    webUrl: "https://writesonic.com/",
    works_on:
      "It is an artificial intelligence tool that helps create content. It can create various types of content such as articles, blog posts, social media posts, email, ads and more.",
    pricing: ["1"],
    typeIcon: ["3", "7", "8"],
    keywords:
      "writesonic, content creation, text creation, article creation, social media post, email creation, ad creation, blog post creation",
  },
  {
    id: 8013,
    name: "Perplexity",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Perplexity AI",
    webUrl: "https://www.perplexity.ai/",
    works_on:
      "It is an AI chatbot that allows users to ask questions and receive answers via chat.",
    pricing: ["0", "1"],
    typeIcon: ["8"],
    keywords: "Perplexity, question answering",
  },
  {
    id: 8014,
    name: "Rytr",
    founding_year: "2021",
    type: "Natural Language Processing",
    developer: "Rytr AI",
    webUrl: "https://rytr.me/",
    works_on:
      "It is an artificial intelligence tool that helps create content. It can create various types of content such as articles, blog posts, social media posts, email, ads and more.",
    pricing: ["1"],
    typeIcon: ["8"],
    keywords:
      "Rytr, content creation, text creation, article creation, social media content creation, blogging, email creation, advertising creation",
  },
  {
    id: 9001,
    name: "DeepL",
    founding_year: "2017",
    type: "Natural Language Processing",
    developer: "Linguee",
    webUrl: "https://www.deepl.com",
    works_on:
      "It is an artificial intelligence tool that translates texts from one language to another and can make improvements according to the structure of the translated language.",
    pricing: ["0", "1"],
    typeIcon: ["8", "9"],
    keywords: "DeepL, translating, making edits to the text",
  },
  {
    id: 10001,
    name: "Leonardo AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Leonardo AI Inc.",
    webUrl: "https://leonardo.ai/",
    works_on:
      "It is an artificial intelligence tool that allows it to create images, drawings, animations, and other visual content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords: "Leonardo AI, draw, create animations, create images",
  },
  {
    id: 10002,
    name: "Adobe Firefly",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Adobe",
    webUrl: "https://firefly.adobe.com/",
    works_on:
      "It is an artificial intelligence tool that allows it to create images, drawings, animations, and other visual content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords:
      "Adobe Firefly, creating images, drawing, creating visual content",
  },
  {
    id: 10003,
    name: "Runway",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Runway AI",
    webUrl: "https://runwayml.com/",
    works_on:
      "It is an artificial intelligence tool that allows it to create images, drawings, animations, videos and other visual content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords:
      "Runway, create images, draw, create videos, create visual content",
  },
  {
    id: 10004,
    name: "VEED.IO",
    founding_year: "2017",
    type: "Natural Language Processing, Image Processing",
    developer: "VEED.IO Pte. Ltd.",
    webUrl: "https://www.veed.io/",
    works_on:
      "It is an artificial intelligence tool that allows creating videos based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["10"],
    keywords: "",
  },
  {
    id: 10005,
    name: "Pictory",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Pictory AI Inc.",
    webUrl: "https://pictory.ai/",
    works_on:
      "It is an artificial intelligence tool that allows creating images, drawings, animations and videos based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["5", "10"],
    keywords: "Pictory, create image, draw, create drawing, create video",
  },
  {
    id: 10007,
    name: "TinyWow",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "TinyWow Teknoloji",
    webUrl: "https://tinywow.com/",
    works_on:
      "It is a website developed by TinyWow Technology, an artificial intelligence company based in Turkey. The site allows users to perform file conversion, image editing, video editing, and other AI-based tasks.",
    pricing: ["0", "1"],
    typeIcon: ["5", "8", "10"],
    keywords:
      "Tinywow, file conversion, image editing, photo editing, video editing",
  },
];
