export const favoryAI = [
  {
    id: 8001,
    name: "ChatGPT",
    webUrl: "https://chat.openai.com",
  },
  {
    id: 8003,
    name: "Bing AI",
    webUrl: "https://www.bing.com",
  },
  {
    id: 8004,
    name: "Claude AI",
    webUrl: "https://claude.ai",
  },
  {
    id: 8005,
    name: "Notion AI",
    webUrl: "https://www.notion.so/product/ai",
  },
  {
    id: 9001,
    name: "DeepL",
    webUrl: "https://www.deepl.com",
  },
  {
    id: 11001,
    name: "Droxy AI",
    webUrl: "https://www.droxy.ai",
  },
  {
    id: 5001,
    name: "Midjourney",
    webUrl: "https://www.midjourney.com",
  },
  {
    id: 5003,
    name: "DALL-E 3",
    webUrl: "https://openai.com/dall-e-3",
  },
  {
    id: 5004,
    name: "Leonardo AI",
    webUrl: "https://leonardo.ai",
  },
  {
    id: 2003,
    name: "Github Copilot",
    webUrl: "https://github.com/features/copilot",
  },
];
