export const TextTr = [
  {
    id: 3001,
    name: "ChatGPT",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "OpenAI",
    webUrl: "https://chat.openai.com/",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, özetleme yapabilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 3002,
    name: "Google Bard",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Google",
    webUrl: "https://bard.google.com/",
    works_on:
      "Bilgi verir, metinleri anlar ve cevaplar üretir, içerik üretir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
  },
  {
    id: 8003,
    name: "Bing AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Microsoft",
    webUrl: "https://www.bing.com/",
    works_on:
      "Metin oluşturabilir, dile çevirisi yapabilir, planlama yapabilir, öneriler verebilir, soru cevaplayabilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
  },
  {
    id: 3004,
    name: "Claude AI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Anthropic",
    webUrl: "https://claude.ai/",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, özetleme yapabilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 8005,
    name: "Notion AI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Notion",
    webUrl: "https://www.notion.so/product/ai",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, verileri analiz edebilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 3006,
    name: "Jasper",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Google",
    webUrl: "https://www.jasper.ai/",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, verileri analiz edebilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 8007,
    name: "OctiAI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Google",
    webUrl: "https://www.octiai.com/",
    works_on:
      "Metin oluşturabilir, dil çevirisi yapabilir, soru cevaplamaya yardımcı olabilir, verileri analiz edebilir ve daha birçok dil işleme görevini gerçekleştirebilir.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 3008,
    name: "Copy.ai",
    founding_year: "2020",
    type: "Doğal Dil İşleme",
    developer: "Copy AI Inc.",
    webUrl: "https://www.copy.ai/",
    works_on:
      "Metin açıklamalarına göre çeşitli yaratıcı içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 8009,
    name: "HyperWrite",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "HyperWrite AI Inc.",
    webUrl: "https://www.hyperwriteai.com/",
    works_on:
      "Metin açıklamalarına göre çeşitli yaratıcı içerikler oluşturmasına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
  },
  {
    id: 8010,
    name: "ChatPDF",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "ChatPDF AI Inc.",
    webUrl: "https://www.chatpdf.com/",
    works_on:
      "Kullanıcıların PDF dosyalarını sohbet ederek anlamalarına ve sorgulamalarına olanak tanıyan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["8"],
  },
  {
    id: 8011,
    name: "writesonic",
    founding_year: "2020",
    type: "Doğal Dil İşleme",
    developer: "Writesonic AI",
    webUrl: "https://writesonic.com/",
    works_on:
      "İçerik oluşturmaya yardımcı olan bir yapay zeka aracıdır. Makaleler, blog gönderileri, sosyal medya gönderileri, e-posta, reklamlar ve daha fazlası gibi çeşitli içerik türleri oluşturabilir.",
    pricing: ["1"],
    typeIcon: ["8"],
  },
  {
    id: 10007,
    name: "TinyWow",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "TinyWow Teknoloji",
    webUrl: "https://tinywow.com/",
    works_on:
      "Türkiye merkezli bir yapay zeka şirketi olan TinyWow Teknoloji tarafından geliştirilen bir web sitesidir. Site, kullanıcıların dosya dönüştürme, görsel düzenleme, video düzenleme ve yapay zeka tabanlı diğer görevleri gerçekleştirmesine olanak tanır.",
    pricing: ["0", "1"],
    typeIcon: ["5", "8", "10"],
  },
  {
    id: 8013,
    name: "Perplexity",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "Perplexity AI",
    webUrl: "https://www.perplexity.ai/",
    works_on:
      "Kullanıcıların sohbet yoluyla soru sormasına ve yanıt almasına olanak tanıyan bir yapay zeka sohbet robotudur.",
    pricing: ["0", "1"],
    typeIcon: ["8"],
  },
  {
    id: 8014,
    name: "Rytr",
    founding_year: "2021",
    type: "Doğal Dil İşleme",
    developer: "Rytr AI",
    webUrl: "https://rytr.me/",
    works_on:
      "İçerik oluşturmaya yardımcı olan bir yapay zeka aracıdır. Makaleler, blog gönderileri, sosyal medya gönderileri, e-posta, reklamlar ve daha fazlası gibi çeşitli içerik türleri oluşturabilir.",
    pricing: ["1"],
    typeIcon: ["8"],
  },
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "Görüntülerdeki nesneleri ve olayları tanımak için, metinleri analiz etmek için ve sesleri tanımak için web sitesi veya API aracılığı ile kullanılabilir. ",
    pricing: ["0", "1"],
    typeIcon: ["1", "2", "5", "8", "10"],
  },
  {
    id: 1002,
    name: "Descript",
    founding_year: "2018",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Descript",
    webUrl: "https://www.descript.com/",
    works_on:
      "Görüntülerdeki nesneleri ve olayları tanımak için, metinleri analiz etmek için ve sesleri tanımak için web sitesi veya API aracılığı ile kullanılabilir. Ses ve videoları metin haline getirebilir. ",
    pricing: ["0", "1"],
    typeIcon: ["1", "8"],
  },
  {
    id: 9001,
    name: "DeepL",
    founding_year: "2017",
    type: "Doğal Dil İşleme",
    developer: "Linguee",
    webUrl: "https://chat.openai.com/",
    works_on:
      "Metinleri bir dilden diğerine çeviren ve çevrilen dilin yapısına göre iyileştirmeler yapabilen bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["8", "9"],
  },
  {
    id: 6001,
    name: "HARPA AI",
    founding_year: "2023",
    type: "Doğal Dil İşleme",
    developer: "Harpa AI Inc.",
    webUrl: "https://harpa.ai/",
    works_on:
      "Kullanıcılar, doğal dil işleme, veri analizi ve öneri sistemleri gibi yapay zeka teknolojilerini kullanarak bilgiye erişebilir, sorular sorabilir ve öneriler alabilir.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8", "6"],
  },
];

export const TextEn = [
  {
    id: 3001,
    name: "ChatGPT",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "OpenAI",
    webUrl: "https://chat.openai.com/",
    works_on:
      "It can compose text, translate languages, help answer questions, summarize, and perform many other language processing tasks.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Chatgpt, text creation, content production, translation, question answering, natural language processing",
  },
  {
    id: 3002,
    name: "Google Bard",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Google",
    webUrl: "https://bard.google.com/",
    works_on:
      "It provides information, understands texts and generates answers, generates content, and can perform many other language processing tasks.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
    keywords:
      "Googl Bard, text creation, question answering, content generation, natural language processing",
  },
  {
    id: 8003,
    name: "Bing AI",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Microsoft",
    webUrl: "https://www.bing.com/",
    works_on:
      "It can create text, translate it into languages, plan, give suggestions, answer questions, and perform many other language processing tasks.",
    pricing: ["0"],
    typeIcon: ["3", "8"],
    keywords:
      "Bing AI, text creation, question answering, content generation,translation, planning, suggestion, natural language processing",
  },
  {
    id: 3004,
    name: "Claude AI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Anthropic",
    webUrl: "https://claude.ai/",
    works_on:
      "It can compose text, translate languages, help answer questions, summarize, and perform many other language processing tasks.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Claude AI, text generation, content generation, translation, question answering, text summarization, natural language processing",
  },
  {
    id: 8005,
    name: "Notion AI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Notion",
    webUrl: "https://www.notion.so/product/ai",
    works_on:
      "It can compose text, translate languages, help answer questions, analyze data, and perform many more language processing tasks.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords:
      "Notion AI, text creation, content generation, question answering, data analysis, translation, natural language processing",
  },
  {
    id: 3006,
    name: "Jasper",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Google",
    webUrl: "https://www.jasper.ai/",
    works_on:
      "It can compose text, translate languages, help answer questions, analyze data, and perform many more language processing tasks.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
    keywords:
      "Jasper, text creation, translation, content generation, data analysis, question answering, natural language processing",
  },
  {
    id: 8007,
    name: "OctiAI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Google",
    webUrl: "https://www.octiai.com/",
    works_on:
      "It can compose text, translate languages, help answer questions, analyze data, and perform many more language processing tasks.",
    pricing: ["1"],
    typeIcon: ["3", "8"],
    keywords:
      "OctiAI, text creation, content generation, question answering, translation, natural language processing",
  },
  {
    id: 3008,
    name: "Copy.ai",
    founding_year: "2020",
    type: "Natural Language Processing",
    developer: "Copy AI Inc.",
    webUrl: "https://www.copy.ai/",
    works_on:
      "It is an artificial intelligence tool that allows creating various creative content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords: "copy.ai, content generation, natural language processing",
  },
  {
    id: 8009,
    name: "HyperWrite",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "HyperWrite AI Inc.",
    webUrl: "https://www.hyperwriteai.com/",
    works_on:
      "It is an artificial intelligence tool that allows creating various creative content based on text descriptions.",
    pricing: ["0", "1"],
    typeIcon: ["3", "8"],
    keywords: "Hyperwrite, content generation, natural language processing",
  },
  {
    id: 8010,
    name: "ChatPDF",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "ChatPDF AI Inc.",
    webUrl: "https://www.chatpdf.com/",
    works_on:
      "It is an artificial intelligence tool that allows users to understand and query PDF files through chat.",
    pricing: ["0", "1"],
    typeIcon: ["8"],
    keywords: "Chatpdf, pdf reading, question and answer",
  },
  {
    id: 8011,
    name: "writesonic",
    founding_year: "2020",
    type: "Natural Language Processing",
    developer: "Writesonic AI",
    webUrl: "https://writesonic.com/",
    works_on:
      "It is an artificial intelligence tool that helps create content. It can create various types of content such as articles, blog posts, social media posts, email, ads and more.",
    pricing: ["1"],
    typeIcon: ["3", "7", "8"],
    keywords:
      "writesonic, content creation, text creation, article creation, social media post, email creation, ad creation, blog post creation",
  },
  {
    id: 10007,
    name: "TinyWow",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "TinyWow Tech",
    webUrl: "https://tinywow.com/",
    works_on:
      "It is a website developed by TinyWow Technology, an artificial intelligence company based in Turkey. The site allows users to perform file conversion, image editing, video editing, and other AI-based tasks.",
    pricing: ["0", "1"],
    typeIcon: ["5", "8", "10"],
    keywords:
      "Tinywow, file conversion, image editing, photo editing, video editing",
  },
  {
    id: 8013,
    name: "Perplexity",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Perplexity AI",
    webUrl: "https://www.perplexity.ai/",
    works_on:
      "It is an AI chatbot that allows users to ask questions and receive answers via chat.",
    pricing: ["0", "1"],
    typeIcon: ["8"],
    keywords: "Perplexity, question answering",
  },
  {
    id: 8014,
    name: "Rytr",
    founding_year: "2021",
    type: "Natural Language Processing",
    developer: "Rytr AI",
    webUrl: "https://rytr.me/",
    works_on:
      "It is an artificial intelligence tool that helps create content. It can create various types of content such as articles, blog posts, social media posts, email, ads and more.",
    pricing: ["1"],
    typeIcon: ["8"],
    keywords:
      "Rytr, content creation, text creation, article creation, social media content creation, blogging, email creation, advertising creation",
  },
  {
    id: 1001,
    name: "Clarif AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Clarifai",
    webUrl: "https://www.clarifai.com/",
    works_on:
      "It can be used via the website or API to recognize objects and events in images, analyze texts, and recognize sounds.",
    pricing: ["0", "1"],
    typeIcon: ["1", "2", "5", "8", "10"],
    keywords:
      "Clarif AI, image identification, photo identification, object identification, text analysis, voice recognition, video identification",
  },
  {
    id: 1002,
    name: "Descript",
    founding_year: "2018",
    type: "Natural Language Processing, Image Processing",
    developer: "Descript",
    webUrl: "https://www.descript.com/",
    works_on:
      "It can be used via the website or API to recognize objects and events in images, analyze texts, and recognize sounds. It can convert audio and videos into text.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8"],
    keywords:
      "Descript, image identification, photo identification, object identification, text analysis, voice recognition, video identification, audio to text conversion",
  },
  {
    id: 9001,
    name: "DeepL",
    founding_year: "2017",
    type: "Natural Language Processing",
    developer: "Linguee",
    webUrl: "https://chat.openai.com/",
    works_on:
      "It is an artificial intelligence tool that translates texts from one language to another and can make improvements according to the structure of the translated language.",
    pricing: ["0", "1"],
    typeIcon: ["8", "9"],
    keywords: "DeepL, translating, making edits to the text",
  },
  {
    id: 6001,
    name: "HARPA AI",
    founding_year: "2023",
    type: "Natural Language Processing",
    developer: "Harpa AI Inc.",
    webUrl: "https://harpa.ai/",
    works_on:
      "Users can access information, ask questions, and receive recommendations using artificial intelligence technologies such as natural language processing, data analysis, and recommendation systems.",
    pricing: ["0", "1"],
    typeIcon: ["1", "8", "6"],
    keywords:
      "Harpa AI, doing research, research artificial intelligence, data analysis, web automation",
  },
];
