import { useEffect, useState } from "react";
//-----Icons & Svg
import Type1 from "../assets/images/aiTypes/1.png";
import Type2 from "../assets/images/aiTypes/2.png";
import Type3 from "../assets/images/aiTypes/3.png";
import Type4 from "../assets/images/aiTypes/4.png";
import Type5 from "../assets/images/aiTypes/5.png";
import Type6 from "../assets/images/aiTypes/6.png";
import Type7 from "../assets/images/aiTypes/7.png";
import Type8 from "../assets/images/aiTypes/8.png";
import Type9 from "../assets/images/aiTypes/9.png";
import Type10 from "../assets/images/aiTypes/10.png";
import Type11 from "../assets/images/aiTypes/11.png";
import Close from "../assets/svg/close.svg";
// import Full from "../assets/svg/full.svg";
import Full from "../assets/svg/link.svg";
import FullModal from "../assets/svg/full.svg";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
//-----Ai Logos
import * as AILogos from "../helpers/AiLogos";
//-----Css
import "./css/Card.css";

const aiTypeIcons = [
  Type1,
  Type2,
  Type3,
  Type4,
  Type5,
  Type6,
  Type7,
  Type8,
  Type9,
  Type10,
  Type11,
];

// const Card = ({ aiData, langPropsData, showDetailProps, cardTypesProps }) => {
const Card = ({ aiData, langPropsData, cardTypesProps }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="card-grid-space">
      {cardTypesProps === "logo" ? (
        <div className="cardAILogo" key={aiData.id}>
          {AILogos[`Img${aiData.id}`] ? null : (
            <div
              // href={aiData.webUrl}
              onClick={handleOpen}
              className="none-logo-ai-name"
            >
              {aiData.name}
            </div>
          )}
          <a
            className="logo-card-cover"
            //  href={aiData.webUrl}
            onClick={handleOpen}
            target="blank"
          >
            <Tooltip
              title={
                langPropsData === "EN"
                  ? `${aiData.name} all details`
                  : "Tüm detaylar"
              }
              placement="top"
              alt={aiData.keywords}
            >
              <img
                src={AILogos[`Img${aiData.id}`] || AILogos.DefaultLogo}
                alt={`Image${aiData.id}`}
              />
            </Tooltip>
          </a>
          <div className="logos-under-div">
            <div>
              {aiData && aiData.typeIcon ? (
                aiData.typeIcon.slice(0, 3).map((aiTypeIcon, index) => {
                  const iconIndex = parseInt(aiTypeIcon) - 1;
                  return (
                    <Tooltip
                      key={index}
                      title={
                        aiTypeIcon === "1"
                          ? langPropsData === "EN"
                            ? "Audio"
                            : "Ses"
                          : aiTypeIcon === "2"
                          ? langPropsData === "EN"
                            ? "Code"
                            : "Kod"
                          : aiTypeIcon === "3"
                          ? langPropsData === "EN"
                            ? "Copywriting"
                            : "Metin Yazarlığı"
                          : aiTypeIcon === "4"
                          ? langPropsData === "EN"
                            ? "Education"
                            : "Eğitim"
                          : aiTypeIcon === "5"
                          ? langPropsData === "EN"
                            ? "Image"
                            : "Resim"
                          : aiTypeIcon === "6"
                          ? langPropsData === "EN"
                            ? "Research"
                            : "Araştırma"
                          : aiTypeIcon === "7"
                          ? langPropsData === "EN"
                            ? "Social Media"
                            : "Sosyal Medya"
                          : aiTypeIcon === "8"
                          ? langPropsData === "EN"
                            ? "Text"
                            : "Metin"
                          : aiTypeIcon === "9"
                          ? langPropsData === "EN"
                            ? "Translation"
                            : "Çeviri"
                          : aiTypeIcon === "10"
                          ? langPropsData === "EN"
                            ? "Video"
                            : "Video"
                          : aiTypeIcon === "11"
                          ? langPropsData === "EN"
                            ? "Generate AI"
                            : "AI Üretmek"
                          : null
                      }
                      alt={aiData.keywords}
                    >
                      <img
                        className="logo-under-icons-in-card"
                        src={aiTypeIcons[iconIndex]}
                        alt="Types of AI, Types of Artifical Intelligence, AI usage areas, Yapay Zeka Türleri, AI Türleri, Yapay Zeka kullanım alanları"
                      />
                    </Tooltip>
                  );
                })
              ) : (
                <p>No icons available</p>
              )}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="modal-content">
                  <div>
                    <div className="modal-header">
                      <h2 alt={aiData.name}>{aiData.name}</h2>
                      <img
                        className="modal-close-svg"
                        onClick={handleClose}
                        src={Close}
                        alt="AI, Yapay Zeka"
                      />
                    </div>
                    <div className="modal-body">
                      <div className="modal-body-both">
                        <div className="modal-body-left-title">
                          {langPropsData === "EN" ? "Developer" : "Geliştirici"}
                        </div>
                        <div>:</div>
                        <div className="modal-body-left-value">
                          {aiData.developer}
                        </div>
                      </div>
                      <div className="modal-body-both">
                        <div className="modal-body-left-title">
                          {langPropsData === "EN" ? "Field" : "Alanı"}
                        </div>
                        <div>:</div>
                        <div className="modal-body-left-value">
                          {aiData.type}
                        </div>
                      </div>
                      <div className="modal-body-both">
                        <div className="modal-body-left-title">
                          {langPropsData === "EN" ? "Web Site" : "Web sitesi"}
                        </div>
                        <div>:</div>
                        <div className="modal-body-left-value">
                          <a
                            target="blank"
                            style={{
                              textDecoration: "none",
                              color: "wheat",
                            }}
                            href={aiData.webUrl ? aiData.webUrl : "#"}
                          >
                            {aiData.webUrl
                              ? aiData.webUrl
                              : langPropsData === "EN"
                              ? "Undifined"
                              : "Belirtilmemiş"}
                          </a>
                        </div>
                      </div>
                      <div className="modal-body-both">
                        <div className="modal-body-left-title">
                          {langPropsData === "EN"
                            ? "Released Date"
                            : "Çıkış Tarihi"}
                        </div>
                        <div>:</div>
                        <div className="modal-body-left-value">
                          {aiData.founding_year}
                        </div>
                      </div>
                      <div className="modal-body-both">
                        <div className="modal-body-left-title">
                          {langPropsData === "EN" ? "Price" : "Fiyat"}
                        </div>
                        <div>:</div>
                        <div className="modal-body-left-value">
                          {aiData.pricing &&
                          aiData.pricing.length !== undefined ? (
                            aiData.pricing.length < 2 ? (
                              aiData.pricing.map((priceData, index) => (
                                <div className="price-ai" key={index}>
                                  {priceData === "0"
                                    ? langPropsData === "EN"
                                      ? "Free"
                                      : "Ücretsiz"
                                    : priceData === "1"
                                    ? langPropsData === "EN"
                                      ? "Paid"
                                      : "Ücretli"
                                    : null}
                                </div>
                              ))
                            ) : (
                              aiData.pricing.map((priceData, index) => (
                                <div className="price-ai" key={index}>
                                  {priceData === "0"
                                    ? langPropsData === "EN"
                                      ? "Free &"
                                      : "Ücretsiz &"
                                    : priceData === "1"
                                    ? langPropsData === "EN"
                                      ? "Paid"
                                      : "Ücretli"
                                    : null}
                                </div>
                              ))
                            )
                          ) : (
                            <div>aiData.pricing is undefined or null</div>
                          )}
                        </div>
                      </div>
                      <div className="modal-body-both">
                        <div className="modal-body-left-title">
                          {langPropsData === "EN" ? "Purpose" : "Amacı"}
                        </div>
                        <div>:</div>
                        <div className="modal-body-left-value">
                          {aiData.works_on}
                        </div>
                      </div>
                      <div
                        style={{ border: "none" }}
                        className="modal-body-both"
                      >
                        <div className="modal-body-left-title">
                          {langPropsData === "EN"
                            ? "Categories"
                            : "Kategoriler"}
                        </div>
                        <div>:</div>
                        <div className="modal-body-left-value">
                          {aiData && aiData.typeIcon ? (
                            aiData.typeIcon.map((aiTypeIcon, index) => {
                              const iconIndex = parseInt(aiTypeIcon) - 1;
                              return (
                                <Tooltip
                                  key={index}
                                  title={
                                    aiTypeIcon === "1"
                                      ? langPropsData === "EN"
                                        ? "Audio"
                                        : "Ses"
                                      : aiTypeIcon === "2"
                                      ? langPropsData === "EN"
                                        ? "Code"
                                        : "Kod"
                                      : aiTypeIcon === "3"
                                      ? langPropsData === "EN"
                                        ? "Copywriting"
                                        : "Metin Yazarlığı"
                                      : aiTypeIcon === "4"
                                      ? langPropsData === "EN"
                                        ? "Education"
                                        : "Eğitim"
                                      : aiTypeIcon === "5"
                                      ? langPropsData === "EN"
                                        ? "Image"
                                        : "Resim"
                                      : aiTypeIcon === "6"
                                      ? langPropsData === "EN"
                                        ? "Research"
                                        : "Araştırma"
                                      : aiTypeIcon === "7"
                                      ? langPropsData === "EN"
                                        ? "Social Media"
                                        : "Sosyal Medya"
                                      : aiTypeIcon === "8"
                                      ? langPropsData === "EN"
                                        ? "Text"
                                        : "Metin"
                                      : aiTypeIcon === "9"
                                      ? langPropsData === "EN"
                                        ? "Translation"
                                        : "Çeviri"
                                      : aiTypeIcon === "10"
                                      ? langPropsData === "EN"
                                        ? "Video"
                                        : "Video"
                                      : aiTypeIcon === "11"
                                      ? langPropsData === "EN"
                                        ? "Generate AI"
                                        : "AI Üretmek"
                                      : null
                                  }
                                >
                                  <img
                                    className="icons-in-model-type"
                                    src={aiTypeIcons[iconIndex]}
                                    alt="Types of AI, Types of Artifical Intelligence, AI usage areas, Yapay Zeka Türleri, AI Türleri, Yapay Zeka kullanım alanları"
                                  />
                                </Tooltip>
                              );
                            })
                          ) : (
                            <p>No icons available</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* YOUTUBE VİDEOSU BURAYA GELECEK */}
                  </div>
                </Box>
              </Modal>
            </div>
            <Tooltip
              title={
                langPropsData === "EN"
                  ? `Visit ${aiData.name}`
                  : `${aiData.name} ziyaret et`
              }
            >
              <a href={aiData.webUrl} target="blank">
                <img
                  className="modal-open-svg-for-logo"
                  // onClick={handleOpen}
                  src={Full}
                  alt="Yapay Zeka,Yapay Zeka Nedir?, Yapay Zeka hakkında, Artifical Intelligence, Ai, Ai Info"
                />
              </a>
            </Tooltip>
          </div>
        </div>
      ) : (
        <>
          <a
            className="card card-cover"
            // onClick={handleOpen}
            // href={aiData.webUrl}
            // target="blank"
          >
            <div className="card-type-icons">
              {/* <div onClick={handleOpen}>
                <img
                  className="modal-open-svg"
                  src={FullModal}
                  alt={aiData.keywords}
                />
              </div> */}
              {aiData && aiData.typeIcon ? (
                aiData.typeIcon.slice(0, 3).map((aiTypeIcon, index) => {
                  const iconIndex = parseInt(aiTypeIcon) - 1;
                  return (
                    <Tooltip
                      key={index}
                      title={
                        aiTypeIcon === "1"
                          ? langPropsData === "EN"
                            ? "Audio"
                            : "Ses"
                          : aiTypeIcon === "2"
                          ? langPropsData === "EN"
                            ? "Code"
                            : "Kod"
                          : aiTypeIcon === "3"
                          ? langPropsData === "EN"
                            ? "Copywriting"
                            : "Metin Yazarlığı"
                          : aiTypeIcon === "4"
                          ? langPropsData === "EN"
                            ? "Education"
                            : "Eğitim"
                          : aiTypeIcon === "5"
                          ? langPropsData === "EN"
                            ? "Image"
                            : "Resim"
                          : aiTypeIcon === "6"
                          ? langPropsData === "EN"
                            ? "Research"
                            : "Araştırma"
                          : aiTypeIcon === "7"
                          ? langPropsData === "EN"
                            ? "Social Media"
                            : "Sosyal Medya"
                          : aiTypeIcon === "8"
                          ? langPropsData === "EN"
                            ? "Text"
                            : "Metin"
                          : aiTypeIcon === "9"
                          ? langPropsData === "EN"
                            ? "Translation"
                            : "Çeviri"
                          : aiTypeIcon === "10"
                          ? langPropsData === "EN"
                            ? "Video"
                            : "Video"
                          : aiTypeIcon === "11"
                          ? langPropsData === "EN"
                            ? "Generate AI"
                            : "AI Üretmek"
                          : null
                      }
                      alt={aiData.keywords}
                    >
                      <img
                        className="icons-in-card"
                        src={aiTypeIcons[iconIndex]}
                        alt="Types of AI, Types of Artifical Intelligence, AI usage areas, Yapay Zeka Türleri, AI Türleri, Yapay Zeka kullanım alanları"
                      />
                    </Tooltip>
                  );
                })
              ) : (
                <p>No icons available</p>
              )}
            </div>
            <div>
              <Tooltip
                title={langPropsData === "EN" ? "Developer" : "Geliştirici"}
                placement="top"
                alt={aiData.keywords}
              >
                <div className="card-ai-developer">{aiData.developer}</div>
              </Tooltip>
              <div className="tags">
                <div className="card-ai-name">{aiData.name}</div>
                <button style={{ background: "none", border: "none" }}>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="modal-content">
                      <div>
                        <div className="modal-header">
                          <h2 alt={aiData.name}>{aiData.name}</h2>
                          <img
                            className="modal-close-svg"
                            onClick={handleClose}
                            src={Close}
                            alt="AI, Yapay Zeka"
                          />
                        </div>
                        <div className="modal-body">
                          <div className="modal-body-both">
                            <div className="modal-body-left-title">
                              {langPropsData === "EN"
                                ? "Developer"
                                : "Geliştirici"}
                            </div>
                            <div>:</div>
                            <div className="modal-body-left-value">
                              {aiData.developer}
                            </div>
                          </div>
                          <div className="modal-body-both">
                            <div className="modal-body-left-title">
                              {langPropsData === "EN" ? "Field" : "Alanı"}
                            </div>
                            <div>:</div>
                            <div className="modal-body-left-value">
                              {aiData.type}
                            </div>
                          </div>
                          <div className="modal-body-both">
                            <div className="modal-body-left-title">
                              {langPropsData === "EN"
                                ? "Web Site"
                                : "Web sitesi"}
                            </div>
                            <div>:</div>
                            <div className="modal-body-left-value">
                              <a
                                target="blank"
                                style={{
                                  textDecoration: "none",
                                  color: "wheat",
                                }}
                                href={aiData.webUrl ? aiData.webUrl : "#"}
                              >
                                {aiData.webUrl
                                  ? aiData.webUrl
                                  : langPropsData === "EN"
                                  ? "Undifined"
                                  : "Belirtilmemiş"}
                              </a>
                            </div>
                          </div>
                          <div className="modal-body-both">
                            <div className="modal-body-left-title">
                              {langPropsData === "EN"
                                ? "Released Date"
                                : "Çıkış Tarihi"}
                            </div>
                            <div>:</div>
                            <div className="modal-body-left-value">
                              {aiData.founding_year}
                            </div>
                          </div>
                          <div className="modal-body-both">
                            <div className="modal-body-left-title">
                              {langPropsData === "EN" ? "Price" : "Fiyat"}
                            </div>
                            <div>:</div>
                            <div className="modal-body-left-value">
                              {aiData.pricing &&
                              aiData.pricing.length !== undefined ? (
                                aiData.pricing.length < 2 ? (
                                  aiData.pricing.map((priceData, index) => (
                                    <div className="price-ai" key={index}>
                                      {priceData === "0"
                                        ? langPropsData === "EN"
                                          ? "Free"
                                          : "Ücretsiz"
                                        : priceData === "1"
                                        ? langPropsData === "EN"
                                          ? "Paid"
                                          : "Ücretli"
                                        : null}
                                    </div>
                                  ))
                                ) : (
                                  aiData.pricing.map((priceData, index) => (
                                    <div className="price-ai" key={index}>
                                      {priceData === "0"
                                        ? langPropsData === "EN"
                                          ? "Free &"
                                          : "Ücretsiz &"
                                        : priceData === "1"
                                        ? langPropsData === "EN"
                                          ? "Paid"
                                          : "Ücretli"
                                        : null}
                                    </div>
                                  ))
                                )
                              ) : (
                                <div>aiData.pricing is undefined or null</div>
                              )}
                            </div>
                          </div>
                          <div className="modal-body-both">
                            <div className="modal-body-left-title">
                              {langPropsData === "EN" ? "Purpose" : "Amacı"}
                            </div>
                            <div>:</div>
                            <div className="modal-body-left-value">
                              {aiData.works_on}
                            </div>
                          </div>
                          <div
                            style={{ border: "none" }}
                            className="modal-body-both"
                          >
                            <div className="modal-body-left-title">
                              {langPropsData === "EN"
                                ? "Categories"
                                : "Kategoriler"}
                            </div>
                            <div>:</div>
                            <div className="modal-body-left-value">
                              {aiData && aiData.typeIcon ? (
                                aiData.typeIcon.map((aiTypeIcon, index) => {
                                  const iconIndex = parseInt(aiTypeIcon) - 1;
                                  return (
                                    <Tooltip
                                      key={index}
                                      title={
                                        aiTypeIcon === "1"
                                          ? langPropsData === "EN"
                                            ? "Audio"
                                            : "Ses"
                                          : aiTypeIcon === "2"
                                          ? langPropsData === "EN"
                                            ? "Code"
                                            : "Kod"
                                          : aiTypeIcon === "3"
                                          ? langPropsData === "EN"
                                            ? "Copywriting"
                                            : "Metin Yazarlığı"
                                          : aiTypeIcon === "4"
                                          ? langPropsData === "EN"
                                            ? "Education"
                                            : "Eğitim"
                                          : aiTypeIcon === "5"
                                          ? langPropsData === "EN"
                                            ? "Image"
                                            : "Resim"
                                          : aiTypeIcon === "6"
                                          ? langPropsData === "EN"
                                            ? "Research"
                                            : "Araştırma"
                                          : aiTypeIcon === "7"
                                          ? langPropsData === "EN"
                                            ? "Social Media"
                                            : "Sosyal Medya"
                                          : aiTypeIcon === "8"
                                          ? langPropsData === "EN"
                                            ? "Text"
                                            : "Metin"
                                          : aiTypeIcon === "9"
                                          ? langPropsData === "EN"
                                            ? "Translation"
                                            : "Çeviri"
                                          : aiTypeIcon === "10"
                                          ? langPropsData === "EN"
                                            ? "Video"
                                            : "Video"
                                          : aiTypeIcon === "11"
                                          ? langPropsData === "EN"
                                            ? "Generate AI"
                                            : "AI Üretmek"
                                          : null
                                      }
                                    >
                                      <img
                                        className="icons-in-model-type"
                                        src={aiTypeIcons[iconIndex]}
                                        alt="Types of AI, Types of Artifical Intelligence, AI usage areas, Yapay Zeka Türleri, AI Türleri, Yapay Zeka kullanım alanları"
                                      />
                                    </Tooltip>
                                  );
                                })
                              ) : (
                                <p>No icons available</p>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* YOUTUBE VİDEOSU BURAYA GELECEK */}
                      </div>
                    </Box>
                  </Modal>
                </button>
              </div>
            </div>
          </a>
        </>
      )}
    </div>
  );
};

export default Card;
