import { useState } from "react";
import Navbar from "./components/Navbar";
import Carousel from "./components/CarouselComp";
import SearchBar from "./components/SearchBar";
import WhatIsAI from "./components/WhatIsAI";
import Blog from "./components/Blog";
import FAQ from "./components/FAQ";
import About from "./components/Contact";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  const [langPropsData, setLangPropsData] = useState("EN");
  const [numberPropsData, setNumberPropsData] = useState("0");
  const [isSearched, setIsSearched] = useState(false);
  // const [themePropsData, setThemePropsData] = useState("dark");

  // const setThemeProps = (theme) => {
  //   // Theme ile ilgili yapılacak işlemler
  //   console.log(`Theme set to: ${theme}`);
  // };

  return (
    // <div className={themePropsData === "dark" ? "App-dark" : "App-light"}>
      <div className="App-dark">
      <Navbar
        setLangProps={setLangPropsData}
        setNumberProps={setNumberPropsData}
      />
      {numberPropsData === "0" ? (
        <>
          <Carousel langPropsData={langPropsData} />
          <SearchBar
            langPropsData={langPropsData}
            setIsSearched={setIsSearched}
            // setThemeProps={setThemePropsData}
            // setThemeProps={setThemeProps}
          />
          <Footer langPropsData={langPropsData} />
        </>
      ) : numberPropsData === "1" ? (
        <WhatIsAI langPropsData={langPropsData} />
      ) : numberPropsData === "2" ? (
        <Blog langPropsData={langPropsData} />
      ) : numberPropsData === "3" ? (
        <div className="App-component-center">
          <About langPropsData={langPropsData} />
        </div>
      ) : numberPropsData === "4" ? (
        <div className="App-component-center">
          <FAQ langPropsData={langPropsData} />
        </div>
      ) : null}
    </div>
  );
}

export default App;
