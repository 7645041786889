export const EducationTr = [
  {
    id: 4001,
    name: "CopyLeaks",
    founding_year: "2015",
    type: "Doğal Dil İşleme",
    developer: "CopyLeaks",
    webUrl: "https://copyleaks.com/",
    works_on:
      "Metin içinde benzersiz olmayan içeriği tespit etmek için kullanılır.",
    pricing: ["0", "1"],
    typeIcon: ["3", "4", "8"],
  },
  {
    id: 4002,
    name: "Scisummary",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "SciSpace",
    webUrl: "https://scisummary.com/",
    works_on:
      "Akademik makaleleri özetlemek için kullanılan bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
  },
  {
    id: 4003,
    name: "Grammarly",
    founding_year: "2009",
    type: "Doğal Dil İşleme",
    developer: "Blackstone",
    webUrl: "https://www.grammarly.com/",
    works_on:
      "Kullanıcıların yazılarını denetlerken dilbilgisi kurallarına, yazım kurallarına ve cümle yapısına dayalı önerilerde bulunur. ",
    pricing: ["0", "1"],
    typeIcon: ["4"],
  },
  {
    id: 4004,
    name: "Beautiful AI",
    founding_year: "2019",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Beautiful.ai Inc.",
    webUrl: "https://www.beautiful.ai/",
    works_on:
      "Profesyonel görünümlü sunumlar oluşturmak için kullanılan bir yapay zeka araçları paketidir.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
  },
];

export const EducationEn = [
  {
    id: 4001,
    name: "CopyLeaks",
    founding_year: "2015",
    type: "Natural Language Processing",
    developer: "CopyLeaks",
    webUrl: "https://copyleaks.com/",
    works_on: "It is used to detect non-unique content within text.",
    pricing: ["0", "1"],
    typeIcon: ["3", "4", "8"],
    keywords: "Copyleaks, plagiarism, similarity detection",
  },
  {
    id: 4002,
    name: "Scisummary",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "Hunts AI",
    webUrl: "https://scisummary.com/",
    works_on:
      "It is an artificial intelligence tool used to summarize academic articles.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
    keywords:
      "scisummary, article summary, article summarization, text summarization",
  },
  {
    id: 4003,
    name: "Grammarly",
    founding_year: "2009",
    type: "Natural Language Processing",
    developer: "Grammarly Inc.",
    webUrl: "https://www.grammarly.com/",
    works_on:
      "Grammarly is an artificial intelligence application that detects grammatical, spelling and style errors and suggests corrections. It can also perform operations such as responding to users' e-mails, rewriting existing content for clarity, and extending the content in accordance with the specified word count.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
    keywords:
      "grammarly, typo, email reply, rewrite, grammar, inconsistency detection",
  },
  {
    id: 4004,
    name: "Beautiful AI",
    founding_year: "2019",
    type: "Natural Language Processing, Image Processing",
    developer: "Beautiful.ai Inc.",
    webUrl: "https://www.beautiful.ai/",
    works_on:
      "It is a suite of artificial intelligence tools used to create professional-looking presentations.",
    pricing: ["0", "1"],
    typeIcon: ["4"],
    keywords: "Beautiful AI, presentation creation",
  },
];
