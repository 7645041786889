export const TranslationTr = [
  {
    id: 9001,
    name: "DeepL",
    founding_year: "2017",
    type: "Doğal Dil İşleme",
    developer: "Linguee",
    webUrl: "https://chat.openai.com/",
    works_on:
      "Metinleri bir dilden diğerine çeviren ve çevrilen dilin yapısına göre iyileştirmeler yapabilen bir yapay zeka aracıdır.",
    pricing: ["0", "1"],
    typeIcon: ["8", "9"],
  },
];

export const TranslationEn = [
  {
    id: 9001,
    name: "DeepL",
    founding_year: "2017",
    type: "Natural Language Processing",
    developer: "Linguee",
    webUrl: "https://chat.openai.com/",
    works_on:
      "It is an artificial intelligence tool that translates texts from one language to another and can make improvements according to the structure of the translated language.",
    pricing: ["0", "1"],
    typeIcon: ["8", "9"],
    keywords: "DeepL, translating, making edits to the text",
  },
];
