import { useEffect, useState } from "react";
import { favoryAI } from "../context/Favories/FavoriesAi";
import Carousel from "react-multi-carousel";
import ShowCarousel from "../assets/svg/arrowDown.svg";
import HideCarousel from "../assets/svg/arrowUp.svg";
import "react-multi-carousel/lib/styles.css";
import "./css/Carousel.css";
import { Tooltip } from "@mui/material";

function CarouselComp({ langPropsData }) {
  const [hideCarousel, setHideCarouse] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const toggleCloseCarousel = () => {
    setHideCarouse(!hideCarousel);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.scrollY > window.innerHeight &&
        window.scrollY <= window.innerHeight * 2
      ) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const responsive = {
    desktopLarge: {
      breakpoint: { max: 3000, min: 1500 },
      items: 6,
      slidesToSlide: 3,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 5,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 4,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 3,
      slidesToSlide: 2,
    },
  };

  return (
    <div className="carousel-cover">
      {hideCarousel ? null : (
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          containerClassName="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClassName="custom-dot-list-style"
          itemClassName="carousel-item"
        >
          {favoryAI.map((aiData) => (
            <Tooltip
            key={aiData.id}
              title={
                langPropsData === "EN"
                  ? `Visit ${aiData.name}`
                  : `${aiData.name} ziyaret et`
              }
              placement="bottom"
              alt={aiData.keywords}
            >
              <a href={aiData.webUrl} key={aiData.id} target="blank">
                <div className="wrapper">
                  <div className="carousel-card">
                    <div className="poster">
                      <img
                      key={aiData.id}
                        src={require(`../assets/images/favories/${aiData.id}.jpg`)}
                        alt={aiData.name}
                      />
                    </div>
                  </div>
                </div>
              </a>
            </Tooltip>
          ))}
        </Carousel>
      )}
      <div className="hide-carousel-div">
        <img
          style={{ width: "30px", cursor: "pointer" }}
          src={!hideCarousel ? HideCarousel : ShowCarousel}
          alt="Favorite AI, Favori Yapay Zekalar"
          onClick={toggleCloseCarousel}
        />
        <div className="hide-carousel-text" onClick={toggleCloseCarousel}>
          {!hideCarousel
            ? langPropsData === "EN"
              ? "Hide Favories"
              : "Favorileri gizle"
            : langPropsData === "EN"
            ? "Show Favories"
            : "Favorileri göster"}
        </div>
      </div>
    </div>
  );
}

export default CarouselComp;
