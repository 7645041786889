import { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AccordionClose from "../assets/svg/down.svg";
import AccordionOpen from "../assets/svg/up.svg";
import "./css/Faq.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function FAQ({ langPropsData }) {
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div style={{ width: "70%", marginTop: "5%" }}>
      <h1 style={{ textAlign: "center", color: "white" }}>
        {langPropsData === "EN"
          ? "Frequently Asked Questions"
          : "Sıkça Sorulan Sorular"}
      </h1>
      <Accordion
        id="accordion-panel-title"
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>
            {langPropsData === "EN"
              ? "What is Artificial Intelligence?"
              : "Yapay zeka nedir?"}
          </Typography>
          <img
            className="accordion-up-down-img"
            src={expanded === "panel1" ? AccordionOpen : AccordionClose}
            alt=""
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-panel-content">
          <Typography>
            {langPropsData === "EN"
              ? `Artificial intelligence is the application of human-like intelligence and abilities to computer systems.
                It is a technology used to gain machine learning, deep
                It includes subfields such as learning and natural language processing.`
              : `Yapay zeka, bilgisayar sistemlerine insan benzeri zekâ ve yetenekler
                kazandırmak için kullanılan bir teknolojidir. Makine öğrenimi, derin
                öğrenme ve doğal dil işleme gibi alt alanları içerir.`}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="accordion-panel-title"
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            {langPropsData === "EN"
              ? "What is the difference between Machine Learning and Artificial Intelligence?"
              : "Makine öğrenimi ile yapay zeka arasındaki fark nedir?"}
          </Typography>
          <img
            className="accordion-up-down-img"
            src={expanded === "panel2" ? AccordionOpen : AccordionClose}
            alt=""
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-panel-content">
          <Typography>
            {langPropsData === "EN"
              ? `Machine learning enables computer systems to learn from data
               It is a sub-branch that provides Artificial intelligence is generally computer
               It aims to provide intelligence and capability to its systems.`
              : `Makine öğrenimi, bilgisayar sistemlerinin verilerden öğrenmesini
                sağlayan bir alt dalıdır. Yapay zeka ise genel olarak bilgisayar
                sistemlerine zekâ ve yetenek kazandırmayı amaçlar.`}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="accordion-panel-title"
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            {langPropsData === "EN"
              ? "What is Deep Learning, and how does it work?"
              : "Derin öğrenme nedir ve nasıl çalışır?"}
          </Typography>
          <img
            className="accordion-up-down-img"
            src={expanded === "panel3" ? AccordionOpen : AccordionClose}
            alt=""
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-panel-content">
          <Typography>
            {langPropsData === "EN"
              ? `Deep learning solves complex tasks using artificial neural networks.
                It is a machine learning technique that learns. This method is multi-layered
                involves the use of artificial neural networks.`
              : `Derin öğrenme, yapay sinir ağlarını kullanarak karmaşık görevleri
                öğrenen bir makine öğrenimi tekniğidir. Bu yöntem, çok katmanlı
                yapay sinir ağlarının kullanılmasını içerir.`}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="accordion-panel-title"
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            {langPropsData === "EN"
              ? "What are the ethical issues in Artificial Intelligence?"
              : "Yapay zeka etik meseleleri nelerdir?"}
          </Typography>
          <img
            className="accordion-up-down-img"
            src={expanded === "panel4" ? AccordionOpen : AccordionClose}
            alt=""
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-panel-content">
          <Typography>
            {langPropsData === "EN"
              ? `Artificial intelligence ethics, automatic decision algorithms
                its effects on and the use of this technology.
                It covers an area that examines the problems that may arise.`
              : `Yapay zeka etiği, otomatik karar algoritmalarının insanlar
                üzerindeki etkilerini ve bu teknolojinin kullanımında ortaya
                çıkabilecek sorunları inceleyen bir alanı kapsar.`}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="accordion-panel-title"
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            {langPropsData === "EN"
              ? "What is Natural Language Processing, and why is it important?"
              : "Doğal dil işleme nedir ve neden önemlidir?"}
          </Typography>
          <img
            className="accordion-up-down-img"
            src={expanded === "panel5" ? AccordionOpen : AccordionClose}
            alt=""
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-panel-content">
          <Typography>
            {langPropsData === "EN"
              ? `Natural language processing is how computer systems understand human language,
                It is a field of technology used to interpret and produce. This,
                studies on text data and language-based tasks
                is important for them to achieve.`
              : `Doğal dil işleme, bilgisayar sistemlerinin insan dilini anlaması,
                yorumlaması ve üretmesi için kullanılan bir teknoloji alanıdır. Bu,
                metin verileri üzerinde çalışmaları ve dil tabanlı görevleri
                gerçekleştirmeleri için önemlidir.`}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="accordion-panel-title"
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            {langPropsData === "EN"
              ? "Why is AI security critical?"
              : "Yapay zeka güvenliği neden kritiktir?"}
          </Typography>
          <img
            className="accordion-up-down-img"
            src={expanded === "panel6" ? AccordionOpen : AccordionClose}
            alt=""
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-panel-content">
          <Typography>
            {langPropsData === "EN"
              ? `Artificial intelligence security, protection against abuse of algorithms and systems
                to prevent its use, to correct misunderstandings, and to
                It is important to prevent unconscious mistakes.`
              : `Yapay zeka güvenliği, algoritmaların ve sistemlerin kötüye
                kullanılmasını önlemek, yanlış anlamaları düzeltmek ve bilinçli veya
                bilinçsiz hataları engellemek için önemlidir.`}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="accordion-panel-title"
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>
            {langPropsData === "EN"
              ? "In which sectors will AI be influential in the future?"
              : "Yapay zeka gelecekte hangi sektörlerde etkili olacak?"}
          </Typography>
          <img
            className="accordion-up-down-img"
            src={expanded === "panel7" ? AccordionOpen : AccordionClose}
            alt=""
          />
        </AccordionSummary>
        <AccordionDetails className="accordion-panel-content">
          <Typography>
            {langPropsData === "EN"
              ? `Artificial intelligence has many applications such as health, finance, automotive, education and production.
                will be effective in the industry. Especially data analysis, automatic decision making
                and will make a great contribution to areas such as process optimization.`
              : `Yapay zeka, sağlık, finans, otomotiv, eğitim ve üretim gibi birçok
                sektörde etkili olacak. Özellikle veri analizi, otomatik karar verme
                ve süreç optimizasyonu gibi alanlarda büyük katkı sağlayacak.`}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FAQ;
