export const SocialMediaTr = [
  {
    id: 7001,
    name: "Clearscope",
    founding_year: "2017",
    type: "Doğal Dil İşleme",
    developer: "Clearscope AI",
    webUrl: "https://www.clearscope.io/",
    works_on:
      "Web sitelerinin arama motoru optimizasyonu (SEO) performansını iyileştirmeye yardımcı olan bir yapay zeka aracıdır.",
    pricing: ["1"],
    typeIcon: ["7"],
  },
  {
    id: 7002,
    name: "BlogSEO AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme",
    developer: "BlogSEO AI",
    webUrl: "https://www.blogseo.ai/",
    works_on:
      "Arama motoru optimizasyonunu (SEO) iyileştirmeye yardımcı olan bir yapay zeka aracıdır.",
    pricing: ["1"],
    typeIcon: ["7"],
  },
  {
    id: 7003,
    name: "vidIQ",
    founding_year: "2014",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "vidIQ Inc.",
    webUrl: "https://vidiq.com/",
    works_on:
      "YouTube içerik oluşturucuları için bir yapay zeka araçları ve analiz paketidir ancak bazı özellikleri web siteler için de kullanılabilir. Performansınızı izlemenize, analiz etmenize ve geliştirmenize yardımcı olur.",
    pricing: ["0", "1"],
    typeIcon: ["7"],
  },
  {
    id: 7004,
    name: "Seona AI",
    founding_year: "2022",
    type: "Doğal Dil İşleme, Görüntü İşleme",
    developer: "Seona AI Inc.",
    webUrl: "https://seona.usestyle.ai/",
    works_on:
      "Youtube kanalınızın ve web sitenizin performansını izlemenize, analiz etmenize ve geliştirmenize yardımcı olur. ",
    pricing: ["0", "1"],
    typeIcon: ["7"],
  },
  {
    id: 8011,
    name: "writesonic",
    founding_year: "2020",
    type: "Doğal Dil İşleme",
    developer: "Writesonic AI",
    webUrl: "https://writesonic.com/",
    works_on:
      "İçerik oluşturmaya yardımcı olan bir yapay zeka aracıdır. Makaleler, blog gönderileri, sosyal medya gönderileri, e-posta, reklamlar ve daha fazlası gibi çeşitli içerik türleri oluşturabilir.",
    pricing: ["1"],
    typeIcon: ["3", "7", "8"],
    keywords:
      "writesonic, içerik üretme, metin oluşturma, makale oluşturma, sosyal medya gönderisi, e-posta oluşturma, reklam oluşturma, blog gönderisi oluşturma",
  },
];

export const SocialMediaEn = [
  {
    id: 7001,
    name: "Clearscope",
    founding_year: "2017",
    type: "Natural Language Processing",
    developer: "Clearscope AI",
    webUrl: "https://www.clearscope.io/",
    works_on:
      "It is an artificial intelligence tool that helps improve the search engine optimization (SEO) performance of websites.",
    pricing: ["1"],
    typeIcon: ["7"],
    keywords:
      "Clearscope, seo, search engine optimization, website, website performance",
  },
  {
    id: 7002,
    name: "BlogSEO AI",
    founding_year: "2022",
    type: "Natural Language Processing",
    developer: "BlogSEO AI",
    webUrl: "https://www.blogseo.ai/",
    works_on:
      "It is an artificial intelligence tool that helps improve search engine optimization (SEO).",
    pricing: ["1"],
    typeIcon: ["7"],
    keywords:
      "blogseo AI, seo, search engine optimization, website, website performance",
  },
  {
    id: 7003,
    name: "vidIQ",
    founding_year: "2014",
    type: "Natural Language Processing, Image Processing",
    developer: "vidIQ Inc.",
    webUrl: "https://vidiq.com/",
    works_on:
      "It is a suite of AI tools and analytics for YouTube creators, but some of its features can also be used for websites. It helps you track, analyze and improve your performance.",
    pricing: ["0", "1"],
    typeIcon: ["7"],
    keywords:
      "vidiq, youtube, analysis, youtube analysis, website analysis, website performance, website performance analysis",
  },
  {
    id: 7004,
    name: "Seona AI",
    founding_year: "2022",
    type: "Natural Language Processing, Image Processing",
    developer: "Seona AI Inc.",
    webUrl: "https://seona.usestyle.ai/",
    works_on:
      "It helps you monitor, analyze and improve the performance of your YouTube channel and website.",
    pricing: ["0", "1"],
    typeIcon: ["7"],
    keywords:
      "Seona AI, youtube channel performance monitoring, youtube channel performance analysis, website performance monitoring, website performance analysis",
  },
  {
    id: 8011,
    name: "writesonic",
    founding_year: "2020",
    type: "Natural Language Processing",
    developer: "Writesonic AI",
    webUrl: "https://writesonic.com/",
    works_on:
      "It is an artificial intelligence tool that helps create content. It can create various types of content such as articles, blog posts, social media posts, email, ads and more.",
    pricing: ["1"],
    typeIcon: ["3", "7", "8"],
    keywords:
      "writesonic, content creation, text creation, article creation, social media post, email creation, ad creation, blog post creation",
  },
];
