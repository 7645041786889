import "./css/Blog.css";

function Blog({ langPropsData }) {
  return (
    <div className="blog-cover">
      <div className="blog-div">
        {langPropsData === "EN" ? "Our blog page" : "Blog sayfamız"}
      </div>
      <div className="blog-div">
        <span className="blog-span">
          {langPropsData === "EN"
            ? "will be up soon!"
            : "yakında hizmetinizde!"}
        </span>
      </div>
      <p className="blog-p">
        {langPropsData === "EN" ? "Stay tuned :)" : "Bizi takipte kalın :)"}
      </p>
    </div>
  );
}

export default Blog;
